/* Module: S */
.page-navigation {
    width: 100%;
    border-top: 1px solid $white-five;
    margin-top: pxToRem(20);
    padding: pxToRem(12) 0;
    position: relative;

    &:after {
        content: '';
        width: 1px;
        position: absolute;
        top: pxToRem(12);
        bottom: pxToRem(12);
        left: 50%;
        background-color: $white-five;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        @extend %clearfix;
    }

    li {
        padding: 0;
        margin: 0;
        float: left;
        width: 50%;
        display: block;

        &:first-child {
            padding-right: pxToRem(24);
            
            a {
                &:after {
                    content: '';
                    position: absolute;
                    top: pxToRem(17);
                    left: 0;
                    content: url(../mysource_files/arrow-orange.svg);
                    width: pxToRem(15);
                    height: pxToRem(11);
                    display: inline-block;
                    @include sq-transform(rotate(180deg));
                }
            }
        }

        &:last-child {
            padding-left: pxToRem(24);
            float: right;
            text-align: right;

            a {
                &:after {
                    content: '';
                    position: absolute;
                    top: pxToRem(7);
                    right: 0;
                    content: url(../mysource_files/arrow-orange.svg);
                    width: pxToRem(15);
                    height: pxToRem(11);
                    display: inline-block;
                }
            }
        }
    }

    a {
        text-decoration: none;
        display: inline-block;
        padding: 0;
        @include font-smoothing;
        position: relative;

        span {
            &:first-child {
                display: block;
                font-size: 1.125em; //18
                font-weight: 300;
                line-height: 1.44;
                color: $dark-orange;
                width: 0;
                overflow: hidden;
            }

            &:last-child {
                display: block;
                font-size: 0.875em; //14
                line-height: 1.43;
                color: $greyish-brown-eight;
                @include sq-transition(color 0.3s);
                padding-top: pxToRem(5);
            }
        }

        &:hover,
        &:focus {
            color: $dark-orange;

            span {
                &:last-child {
                    color: $dark-orange;
                }
            }
        }

    }

    &__title {
        display: none;
    }
}