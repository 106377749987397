/* Module: S */
.resources-block {
	margin: pxToRem(29) 0 0;
	padding: pxToRem(32) pxToRem(18) pxToRem(34);
	background-color: #FAFAFA;

	&__content {
		color: black;
		font-weight: 300;

		p {
			margin: 0 0 pxToRem(3);
		}

		ul {
        list-style-type: none;
        margin: 0;
        padding: 0 0 0 pxToRem(10);

        li {
            display: block;
            margin: pxToRem(10) 0 0;
            padding: 0 0 0 pxToRem(14);
            font-size: 1em;
            font-weight: 300;
            line-height: 1.63;
            position: relative;

            &:after {
                content: '';
                box-sizing: border-box;
                position: absolute;
                top: pxToRem(11);
                left: 0;
                border: 1px solid $dark-orange;
                border-radius: 50%;
                height: pxToRem(4);
                width: pxToRem(4);
                background-color: $dark-orange;
            }

            p {
                margin: 0 0 pxToRem(16);
                padding: 0;
                max-width: pxToRem(480);
                font-size: 1em;
                font-weight: 300;
                line-height: pxToRem(26);
            }
        }
    }

		a {
			padding: pxToRem(10) 0;
			color: $dark-orange;
		}
	}
}