/* Module: S */
.block {	
	background-color: $white-six;
	padding: pxToRem(20) pxToRem(20) pxToRem(30) pxToRem(20);
	margin: pxToRem(20) 0;
	color: $brownish-grey;
	font-size: 1em; //16
	font-weight: 300;
	line-height: 1.63;
    @extend %clearfix; 

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding: pxToRem(10) 0;
    }

    &-50 {
		padding: pxToRem(20);
		margin-top: pxToRem(10);

		p {
			font-size: 1.125em; //18
		}
    }

	&__title,
	h3 {
		font-size: 1em; //16
		font-weight: 500;
		line-height: 1.63;
		padding: pxToRem(20) 0;
		color: $greyish-brown-seven;
	}

	p {
		padding: pxToRem(10) 0;
		margin: 0;
	}
}