/* Module: S */
.header {
	min-height: pxToRem(80);
	position: relative;
	
	&__wrapper {
		@extend %clearfix;
	}

    &__accessibility {
        display: none;
        position: absolute;
        top: 0;
        left: 50%;
        width: pxToRem(300);
        height: pxToRem(19);
        margin-left: pxToRem(-150);

        div {
        	text-align: center;
            text-transform: uppercase;
            font-size: 0.875em; //14
            font-weight: 500;
            color: $black;

            a {
                opacity: 0;
                @include sq-transition(opacity .3s, top .3s); 
                text-decoration: none;
                position: absolute;
                top: pxToRem(-19);
                margin-left: pxToRem(-80);
                min-width: pxToRem(160);
            	color: $black;

                &:focus {
                    opacity: 1;
                	top: 0;
                }
            }

            span {
                opacity: 0;
                @include sq-transition(visibility .3s); 
                position: absolute;
                left: 0;
                right: 0;
                display: block;
                background-color: $white;

                &.active {
                    opacity: 1;
                }
            }

        }

    }

	&__toggle {
		float: left;
		padding: pxToRem(20) pxToRem(10);

		button {
			padding: pxToRem(13) pxToRem(10);
			margin: 0;
			background-color: transparent;
			border: none;
			-webkit-appearance: none;
			display: block;

			&.active {
				.icon {
					span:nth-child(1) {
						top: pxToRem(25);
						width: 0%;
						left: 50%;
					}

					span:nth-child(2) {
						@include sq-transform(rotate(45deg));
					}

					span:nth-child(3) {
						@include sq-transform(rotate(-45deg));
					}

					span:nth-child(4) {
						top: pxToRem(-25);
						width: 0%;
						left: 50%;
					}					
				}
			}
		}

		.icon {
			display: block;
			width: pxToRem(20);
			height: pxToRem(14);
			position: relative;
			@include sq-transform(rotate(0deg));
			@include sq-transition(.5s ease-in-out);
			cursor: pointer;
			overflow: hidden;

			span {
				display: block;
				position: absolute;
				height: pxToRem(2);
				width: 100%;
				background-color: $black;
				opacity: 1;
				left: 0;
				@include sq-transform(rotate(0deg));
				@include sq-transition(.25s ease-in-out);
			}

			span:nth-child(1) {
				top: 0;
			}

			span:nth-child(2),
			span:nth-child(3) {
				top: pxToRem(6);
			}

			span:nth-child(4) {
				top: pxToRem(12);
			}
		}
	}

	&__logo {
		position: absolute;
		top: pxToRem(8);
		left: 50%;
		margin-left: pxToRem(-34);

		a {
			display: block;
		}

		img {
			display: block;
			width: pxToRem(68);
		}
	}

	&__cart {
		float: right;
		height: pxToRem(80);
		padding: pxToRem(20) 0;

		a {
			display: block;
			width: pxToRem(40);
			height: pxToRem(40);
			text-decoration: none;
			position: relative;

			svg {
				position: absolute;
				width: pxToRem(22);
				height: pxToRem(20);
				top: pxToRem(10);
				right: pxToRem(13);
				z-index: -1;
			}

			span {
				&.count {
					display: block;
					font-size: 0.625em; //10
					line-height: pxToRem(18);
					font-weight: 500;
					color: $white;
					background-color: $dark-orange;
					@include font-smoothing();
					border-radius: 100%;
					width: pxToRem(18);
					text-align: center;
					position: absolute;
					top: 0;
					right: 0;
				}
			}
		}
	}

	&__search {
		position: absolute;
		top: pxToRem(80);
		left: 0;
		right: 0;
		background-color: $white-two;
		padding: pxToRem(24) pxToRem(20);
		height: pxToRem(73);
		@include placeholder-color($black);
		display: none;
		z-index: 9;

		&.active {
			display: block;
		}

		form {
			height: pxToRem(44);
			position: absolute;
			top: pxToRem(24);
			left: pxToRem(20);
			right: pxToRem(20);
		}

		input[type="text"] {
            font-size: 1em; //16
            font-weight: 500;
			color: $black;
			border: none;
			border-bottom: 1px solid $greyish;
			background-color: transparent;
			padding: pxToRem(5) pxToRem(26) 0 pxToRem(0);
			width: 100%;
			outline: 0;

			&:focus {
				border-bottom: 1px solid $black;
			}
		}

		input[type="submit"] {
			border: none;
			border-radius: 0;
			background-color: transparent;
			background-image: url(../mysource_files/search.svg);
			background-position: pxToRem(14) pxToRem(5);
			background-repeat: no-repeat;
			background-size: pxToRem(16);
			text-indent: -99999px;
			padding: 0;
			@include clear-default-appearance;
			position: absolute;
			top: 0;
			right: 0;
			padding: 0;
			width: pxToRem(30);
			height: pxToRem(28);
			@include sq-transition(background-color 0.3s);
		}

		&-toggle {
			float: right;
			padding: pxToRem(20) pxToRem(10) pxToRem(20) 0;

			button {
				@include clear-default-appearance;
				border: none;
				border-radius: 0;
				background-color: transparent;
				padding: 0;
				width: pxToRem(40);
				height: pxToRem(40);
				position: relative;
				display: block;

				.close {
					opacity: 0;
					visibility: hidden;
					position: absolute;
					top: pxToRem(12);
					left: pxToRem(12);
					@include sq-transition(opacity 0.3s, visibility 0.3s);
				}

				.search {
					opacity: 1;
					visibility: visible;
					position: absolute;
					top: pxToRem(10);
					left: pxToRem(10);
					@include sq-transition(opacity 0.3s, visibility 0.3s);
				}

				&.active {
					.close {
						opacity: 1;
						visibility: visible;
					}

					.search {
						opacity: 0;
						visibility: hidden;
					}
				}
			}
		}
	}
}