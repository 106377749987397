/*
--------------------
Content
--------------------
*/

hr {
    margin: pxToRem(24) 0;
}

.alert {
    &__wrapper {
        max-width: $max-width;
        margin: 0 auto;
        padding: pxToRem(20) $padding-desktop; 
    }
}

.section {
    &__wrapper {
        padding: pxToRem(40) $padding-desktop; 
    }

    &__row {
    	margin: 0 pxToRem(-16);
    }

    &__column {
    	width: 50%;
    	float: left;
    	padding: 0 pxToRem(16);

        &-75 {
            width: 75%;
            padding: 0 pxToRem(80) 0 pxToRem(16);

            &:last-child {
                padding: 0 pxToRem(16) 0 pxToRem(80);

            }

            > p,
            > h1,
            > h2 {
                &:first-child {
                    padding-top: 0;
                }
            }

            .content {
                &:first-child {
                    > p,
                    > h1,
                    > h2 {
                        &:first-child {
                            padding-top: 0;
                        }
                    }

                }
            }

            > .downloads {
                &:first-child {
                    margin-top: 0;
                }
            }
        }

        &-40 {
            width: 40%;
        }

        &-60 {
            width: 60%;
        }

        &-25 {
            width: 25%;
            padding: 0 0 0 pxToRem(16);

            &:last-child {
                padding: 0 pxToRem(16) 0 0;
            }
        }
    }

    &-merged {
        .section {
            &__wrapper {
                padding-bottom: 0;
                margin-bottom: pxToRem(-40);
            }
        }
    }
}

/*
--------------------
Modules
--------------------
*/