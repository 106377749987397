/* Module: S */
.section-tiles {

	&__title-two {
		margin: 0;
		font-size: pxToRem(36);
		line-height: pxToRem(42);
	}

	&__item {
		&-large-title {
			.section-tiles {
				&__title {
					font-size: 2.250em; //36
					line-height: 1.39;
					padding: pxToRem(30) 0 pxToRem(14) 0;

					a {
						&:after {
						}
					}
				}
			}
		}
	}

	&__title {
		font-size: 1.750em; //28
		line-height: 1.39;
		padding-top: pxToRem(35);

		a {
			&:after {
			}
		}
	}


	&__links {
		ul {
			margin: 0 pxToRem(-20);
		}

		li {
			width: 50%;
			float: left;
			padding: 0 pxToRem(20);
		}
	}
}