/* Module: S */
.side-navigation {
    background-color: transparent;
    padding: 0;
    margin: pxToRem(-10) 0 pxToRem(30) 0;

    ul {
    	border: 1px solid $white-five;
    }

    a {
        font-size: 1.125em; //18
        line-height: 1.44;
        display: block;
        padding: pxToRem(15) pxToRem(60) pxToRem(15) pxToRem(22);
        position: relative;

        &:after {
            position: absolute;
            top: 50%;
            margin-top: pxToRem(-11);
            right: pxToRem(22);
            bottom: 0;
        }

    }

    &__title {
        display: block;
        font-size: 1.500em; //24
		font-weight: 300;
		line-height: 1.46;
		padding: pxToRem(10) 0 pxToRem(10) pxToRem(32);
        position: relative;

        &:after {
            content: url(../mysource_files/menu.svg);
            position: absolute;
            top: pxToRem(9);
            left: 0;
            width: pxToRem(18);
            height: pxToRem(35);
            display: inline-block;
        }
    }
}