/* Module: S */
.tile {
	&:first-child {
		padding-top: 0;
	}

	.events {
		&__item {
			&:last-child {
				border-bottom: 1px solid $white-five;
			}
		}
	}
}