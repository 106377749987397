/* Module: S */
.projects {
	border-top: 1px solid $white-five;
	margin-top: pxToRem(20);

	&__item {
		padding: pxToRem(12) 0 pxToRem(17) 0;
		border-bottom: 1px solid $white-five;
	}

	&__block {
		@extend %clearfix;
		padding-bottom: pxToRem(20);

		h2 {
			padding-top: 0;
			width: 100%;
			float: left;
		}

		.projects {
			&__status {
				width: 100%;
				float: left;
				font-size: 1.125em; //18

				&:before {
					width: pxToRem(16);
					height: pxToRem(16);
					margin-right: pxToRem(12);
				}
			}
		}
	}

	&__state {
		float: left;
		width: 100%;
		padding-top: pxToRem(10);
		font-size: 1.250em; //18
		font-weight: 500;
	}

	&__dates {
		float: left;
		width: 100%;
		padding-top: pxToRem(10);
		font-size: 1.125em; //18
	}

	a {
		text-decoration: none;
		display: block;
		@extend %clearfix;

		&:hover,
		&:focus {
			.projects {
				&__name {
					text-decoration: underline;
				}
			}
		}
	}

	&__number {
		font-size: 0.875em; //14
		font-weight: 500;
		line-height: 1.2;
		padding: pxToRem(5) 0;
		color: $dark-orange;
		@include font-smoothing;
		float: left;
		max-width: 25%;
	}

	&__status {
		font-size: 0.875em; //14
		font-weight: 500;
		line-height: 1.2;
		padding: pxToRem(5) 0;
		@include font-smoothing;
		color: $greyish-brown-two;
		float: right;
		text-align: right;
		max-width: 75%;

		&:before {
			content: url(../mysource_files/status.svg);
			width: pxToRem(12);
			height: pxToRem(12);
			display: inline-block;
			margin-right: pxToRem(8);
		}

		&.on-track {
			color: $jungle-green;

			&:before {
				content: url(../mysource_files/status-on-track.svg);
			}
		}

		&.delays {
			color: $dark-orange;

			&:before {
				content: url(../mysource_files/status-delays.svg);
			}
		}

		&.late {
			color: $scarlet;

			&:before {
				content: url(../mysource_files/status-late.svg);
			}
		}

		&.pending {
			color: $purple;

			&:before {
				content: url(../mysource_files/status-pending.svg);
			}
		}
	}

	&__name {
		font-size: 0.875em; //14
		font-weight: 300;
		line-height: 1.57;
		color: $greyish-brown-two;
		float: left;
		width: 100%;
		padding-top: pxToRem(12);
	}
}