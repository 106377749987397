/* Module: S */
.links-inline {

  &__container {
    margin-top: pxToRem(22);
  }

  &__title {
    padding-bottom: pxToRem(15);
  }

  &__list {

    li {
      display: inline-block;
      padding-right: pxToRem(18);
      width: 33%;

      a {
        margin-top: pxToRem(26);
        padding: 0;
        font-size: pxToRem(24);
        line-height: pxToRem(32);
      }
    }
  }
}