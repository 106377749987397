/* Module: S */
.progress-bar {
	position: absolute;
	bottom: pxToRem(46);
	right: pxToRem(20);
	min-width: 40%;
	padding: 0;

    ol {
        li {
            font-size: 1em; //16
        }
    }
}