/* Module: S */
body {
    @include sq-transition((none)); 
    left: auto;

    .header {
    	@include sq-transition((none)); 
    }

	&.navigation-open {
		left: auto;
		overflow: auto;

		.header {
			left: auto;
			-webkit-transform: translateZ(0);
		}
	}
}

.navigation {
    position: static;
    top: auto;
    left: auto;
    bottom: auto;
    background-color: transparent;
    z-index: 99;
    @include sq-transition((none)); 
    overflow: visible;
    visibility: visible;
    width: 100%;
    @include font-smoothing-reset;

    &.active {
        left: auto;
        visibility: visible;
    }

    &__wrapper {
        position: static;
        top: auto;
        left: auto;
        width: 100%;
        min-height: 0;
        @include sq-transition((none)); 
        padding: 0;
	}

	&__toggle {
	    display: none;
	}

    &__info {
        display: block;
        float: left;
        width: 25%;
        padding-right: pxToRem(60);
    }

    &__title {
    	a {
	        display: block;
	        font-size: 1.375em; //22
	        font-weight: 500;
	        color: $greyish-brown-two;
	        text-decoration: none;
	        @include sq-transition(color 0.3s);

	        &:hover,
	        &:focus {
	        	color: $dark-orange;
	        }
    	}
    }

    &__text {
        font-size: 0.875em; //14
        font-weight: 300;
        color: $greyish-brown-two;
        padding-top: pxToRem(14);
    }

    &__level-1 {
        @include sq-transition(opacity 0.3s, visibility 0.3s); 
        opacity: 1;
        visibility: visible;

        &.search-active {
            opacity: 0;
            visibility: hidden;
        }

        >li {
            border-bottom: none;
            float: left;
            padding: 0 pxToRem(13);

            &:hover,
            &.open {
                .navigation__level-2-wrapper {
                    display: block;
                }

                .navigation__level-1-link {
                    a {
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }

            &.current {
                position: static;

                &:after {
                    display: none;
                }

                .navigation__level-1-link {
                    a {
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &-link {
            &.parent {
                a {
                    padding-right: pxToRem(5);
                }
            }

            a {
                color: $black;
                padding: pxToRem(29) pxToRem(5) pxToRem(25) pxToRem(5);
                position: relative;

                &:focus {
                    &:after {
                        opacity: 1;
                    }
                }
                
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: pxToRem(5);
                    right: pxToRem(5);
                    height: pxToRem(3);
                    background-color: $dark-orange;
                    opacity: 0;
                    @include sq-transition(opacity 0.3s);
                }
            }
        }
    }

    &__level-2 {
        padding: 0;
        margin: 0;
        float: left;
        width: 75%;

        &-inner {
            padding: 0 $padding-desktop;
            max-width: $max-width;
            margin: 0 auto;            
        }

        &-wrapper {
            position: absolute;
            top: pxToRem(139);
            left: 0;
            right: 0;
            width: auto;
            bottom: auto;
            padding: pxToRem(34) pxToRem(40) pxToRem(38) pxToRem(40);
            background-color: $white;
            visibility: visible;
            display: none;
            z-index: 9;
            box-shadow: 0 pxToRem(6) pxToRem(10) pxToRem(-4) rgba($black, 0.16);

            &.open {
                display: block;
            }
        }

        >li {
            padding: 0 pxToRem(25);
            margin: 0;
            list-style-type: none;
            float: left;
            width: 33.333%;

            &:nth-child(3n+1) {
                clear: left;
            }

            a {
                color: $dark-orange;
                display: block;
                font-size: 1em; //16
                font-weight: 500;
                line-height: 1.88;
                max-width: pxToRem(250);
                padding: pxToRem(10) 0;
                text-decoration: none;
                @include sq-transition(color 0.3s);
                @include font-smoothing;

                &:hover,
                &:focus {
                    color: $greyish-brown-three;
                }

                &:after {
                    content: url(../mysource_files/arrow-orange.svg);
                    width: pxToRem(15);
                    height: pxToRem(11);
                    margin-left: pxToRem(10);
                    display: inline-block;
                }
            }
        }
    }


    &__secondary {
        position: relative;
        padding: 0;

        ul {
            position: absolute;
            right: pxToRem(-96);
            bottom: pxToRem(58);
        }

        li {
            padding: 0 pxToRem(10);
            margin: 0;
            list-style-type: none;
            float: left;

            a {
                color: $gray-medium;
                padding: pxToRem(5);

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
    }

}