/* Module: S */
.projects {
	margin-top: pxToRem(30);

	&__block {
		padding-bottom: pxToRem(30);

		h2 {
			width: 50%;
			padding-bottom: 0;
		}

		.projects {
			&__status {
				width: 50%;
				text-align: right;
				float: right;
				line-height: 2;
				padding: pxToRem(7) 0;
			}
		}
	}

	&__dates {
		padding-top: pxToRem(20);
		padding-right: pxToRem(50);
		width: auto;
	}
}