/* Module: S */
.pagination {
	padding: pxToRem(20) 0;

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		width: 100%;
        @include sq-flex-box;
        @include sq-flex-direction(row);
        @include sq-justify-content(center);

		li {
			padding: 0;
			margin: 0;
			display: inline-block;
			vertical-align: top;

			a {
				text-decoration: none;
				color: $greyish-brown-four;
				font-size: 1.125em; //18
				font-weight: 300;
				line-height: 1.56;
				padding: pxToRem(5) pxToRem(10);
				display: block;
				@include sq-transition(color 0.3s);
				@include font-smoothing;
				vertical-align: top;
				position: relative;

				&[href] {
					color: $dark-orange;

					&:after {
						content: '';
						position: absolute;
						bottom: 0;
						left: pxToRem(10);
						right: pxToRem(10);
						height: 1px;
						background-color: $dark-orange;
					}

					&:hover,
					&:focus {
						color: $greyish-brown-four;

						svg {
							path {
								fill: $greyish-brown-four;
							}
						}
					}
				}

			}

			&.prev,
			&.next {
				a {
					&:after {
						display: none;
					}
				}
			}

			&.prev {
				svg {
					@include sq-transform(rotate(180deg));
				}
			}
		}
	}
}