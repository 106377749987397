/* Module: S */
.accordion {
	margin: pxToRem(20) 0;
	border: 1px solid $white-seven;

	&__item {
		border-top: 1px solid $white-seven;

		&:first-child {
			border-top: none;
		}
	}

	&__title {
	    position: relative;

		button {
		    width: 100%;
		    padding: pxToRem(20) pxToRem(45) pxToRem(20) pxToRem(20);
		    border: none;
		    border-radius: 0;
		    background-color: transparent;
		    -webkit-appearance: none;
		    text-align: left;
		    font-size: 1.125em; //18
		    font-weight: 300;
  			line-height: 1.11;
		    @include sq-transition(color .3s);   
		    color: $greyish-brown-two;
		    @include font-smoothing;

		    &:hover,
		    &:focus {
		    	color: $dark-orange;
		    }
	        
	        &:before,
	        &:after {
	            content: '';
	            position: absolute;
	            top: pxToRem(32);
	            right: pxToRem(22);
	            background-color: $greyish-brown-two;
	            @include sq-transition((opacity .3s, transform .3s, background-color .3s));   
	        }

	        &:before {
	            width: pxToRem(2);
	            height: pxToRem(10);
	            margin-right: pxToRem(-1);
	            margin-top: pxToRem(-5);
	        }

	        &:after {
	            width: pxToRem(10);
	            height: pxToRem(2);
	            margin-right: pxToRem(-5);
	            margin-top: pxToRem(-1);
	        }
		}

        &.active {
            button {
		    	color: $dark-orange;

	            &:before {
	                @include sq-transform(rotate(90deg));
	            }

	            &:after {
	                @include sq-transform(rotate(90deg));
	                opacity: 0;
	            }
            }
        }
	}

	&__text {
		border-top: 1px solid $white-seven;
		font-size: 1em; //16
		font-weight: 300;
		line-height: 1.5;
		padding: pxToRem(26) pxToRem(20);
		display: none;
		color: $greyish-brown-four;

		&.active {
			display: block;
		}

		p {
			margin: 0;
			padding-bottom: pxToRem(10);

			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}