/* Module: S */
.publications {
	margin-top: pxToRem(15);
	border-top: 1px solid $white-five;

	&__item {
		padding: pxToRem(16) 0;
		border-bottom: 1px solid $white-five;
	}

	&__title {
		font-size: 1em; //16
		font-weight: 300;
		line-height: 1.5;
		color: $greyish-brown-two;

		a[href] {
			text-decoration: none;
			@include sq-transition(color 0.3s);	
			@include font-smoothing;
			color: $greyish-brown-two;

			&:hover,
			&:focus {
				color: $dark-orange;
			}

	        &:after {
				content: url(../mysource_files/arrow-orange.svg);
				width: pxToRem(15);
				height: pxToRem(11);
				margin-left: pxToRem(10);
				display: inline-block;
	        }
		}
	}

	&__code {
		font-size: 0.875em; //14
		font-weight: 300;
		line-height: 1.5;
		color: $greyish-brown-two;
		padding-top: pxToRem(8);
	}
}