/* Module: S */
.search {
    
    &__partial-list {
        top: pxToRem(65);
        left:0;
        right: 0;
        background-color: $white;

        ul {
            padding: pxToRem(28) 0;
        }

        a {
            padding: pxToRem(10) pxToRem(30);
        }

    }
}

