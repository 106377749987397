/* Module: S */
.form {
	@extend %clearfix;
	@include placeholder-color($warm-grey-two);
	padding: pxToRem(20) 0;
    font-size: 1em; //16
    line-height: 1.63;

	&-side {
		padding-top: 0;
	}

	&-clear {
		padding-top: 0;
	}

    .padding {
        padding-top: pxToRem(20);
        padding-bottom: pxToRem(20);

        &-top {
            padding-top: pxToRem(20);
        }

        &-bottom {
            padding-bottom: pxToRem(20);
        }
    }

	label,
	legend,
	.title {
		font-size: 1em; //16
		font-weight: 300;
		color: $greyish-brown-four;
		padding-bottom: pxToRem(6);
		display: block;
	}

	&__text {
        font-size: 0.875em; //14
		font-weight: 300;
		line-height: 1.64;
		padding-top: pxToRem(10);

		a[href] {
			color: $dark-orange;
			@include font-smoothing();
			text-decoration: none;

			&:focus,
			&:hover {
				text-decoration: underline;
			}
		}

		&-top {
			padding-bottom: pxToRem(20);
		}
	}

	&__loader {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
		background-color: rgba($white, 0.5);
		display: none;
	}

	&__spinner {
		width: pxToRem(50);
		height: pxToRem(40);
		text-align: center;
		font-size: pxToRem(10);
		position: fixed;
		top: 50%;
		left: 50%;
		margin: pxToRem(-20) 0 0 pxToRem(-25);

		div {
			background-color: $dark-orange;
			height: 100%;
			width: pxToRem(6);
			display: inline-block;

			-webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
			animation: sk-stretchdelay 1.2s infinite ease-in-out;

			&.form {
				&__spinner {
					&-2 {
						-webkit-animation-delay: -1.1s;
						animation-delay: -1.1s;
					}

					&-3 {
						-webkit-animation-delay: -1.0s;
						animation-delay: -1.0s;
					}

					&-4 {
						-webkit-animation-delay: -0.9s;
						animation-delay: -0.9s;
					}

					&-5 {
						-webkit-animation-delay: -0.8s;
						animation-delay: -0.8s;
					}
				}
			}
		}
	}

	&__error {
		font-size: 0.875em; //14
		font-weight: 300;
		color: $rust-red;
		padding: pxToRem(10) 0 pxToRem(10) pxToRem(16);
		display: none;
		position: relative;

		&:before {
			content: '';
			width: pxToRem(6);
			height: pxToRem(6);
			border-radius: 100%;
			background-color: $rust-red;
			display: block;
			position: absolute;
			top: pxToRem(18);
			left: 0;
		}

		&-technical {
			font-size: 1; //18
			font-weight: 500;
			display: none;
		}

		a[href] {
			color: $rust-red;
			@include font-smoothing-reset();
			text-decoration: underline;

			&:focus,
			&:hover {
				text-decoration: none;
			}
		}

		p {
			margin: 0;
		}

		ul {
			margin: 0;
		}
	}

	input {
		&::-ms-clear {
		    display: none;
		}
	}

	input[type="text"],
	input[type="search"],
	input[type="email"],
	input[type="tel"],
	input[type="password"] {
        font-size: 0.875em; //14
        font-weight: 300;
		color: $black;
		padding: pxToRem(13) pxToRem(16) pxToRem(13) pxToRem(16);
		width: 100%;
		border: 1px solid $white-three;

		&:disabled {
			opacity: 0.5;
		}
	}

	input[type="radio"] {
		position: absolute;
		opacity: 0;

		&:focus {
			& + label {
				&:before {
					outline: auto 5px -webkit-focus-ring-color;
				}
			}
		}

		&:checked {
			& + label {
				&:before {
					border: 1px solid $dark-orange;
				}

				&:after {
					content: '';
					width: pxToRem(6);
					height: pxToRem(6);
					border-radius: 100%;
					position: absolute;
					left: pxToRem(4);
					top: pxToRem(13);
					background-color: $dark-orange;
				}
			}
		}

		&:disabled {
			& + label {
				&:before {
					opacity: 0.5;
				}

				&:after {
					opacity: 0.5;
				}
			}
		}

		& + label {
			position: relative;
			padding: pxToRem(6) 0 pxToRem(6) pxToRem(24);
			display: inline-block;
	        font-size: 0.875em; //14
	        font-weight: 300;
	        color: $greyish-brown-two;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: pxToRem(9);
				width: pxToRem(14);
				height: pxToRem(14);
				border-radius: 100%;
				border: 1px solid $white-eight;
			}
		}
	}

	input[type="checkbox"] {
		position: absolute;
		opacity: 0;

		&:focus {
			& + label {
				&:before {
					outline: auto 5px -webkit-focus-ring-color;
				}
			}
		}

		&:checked{
			& + label {
				&:before {
					border: 1px solid $dark-orange;
				}

				&:after {
					content: url(../mysource_files/tick.svg);
					position: absolute;
					left: pxToRem(3);
					top: pxToRem(3);
					width: pxToRem(8);
					height: pxToRem(6);
				}
			}
		}

		&:disabled {
			& + label {
				&:before {
					opacity: 0.5;
				}

				&:after {
					opacity: 0.5;
				}
			}
		}

		& + label {
			position: relative;
			padding: pxToRem(6) 0 pxToRem(6) pxToRem(24);
			display: inline-block;
	        font-size: 0.875em; //14
	        font-weight: 300;
	        color: $greyish-brown-two;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: pxToRem(9);
				width: pxToRem(14);
				height: pxToRem(14);
				border: 1px solid $white-eight;
			}
		}
	}

	input[type="submit"] {
		margin-top: pxToRem(24);
		border: 1px solid $dark-orange;
		border-radius: 0;
		background-color: $dark-orange;
		color: $white;
		font-size: 1em; //16
		line-height: 1.45;
		font-weight: 500;
		padding: pxToRem(10) pxToRem(20);
		@include clear-default-appearance;
		@include font-smoothing;
		@include sq-transition(background-color 0.3s, color 0.3s, border-color 0.3s);

		&:hover,
		&:focus {
			color: $dark-orange;
			background-color: transparent;
		}

		&.invert {
			color: $greyish-brown;
			border-color: $greyish-brown;
			background-color: $white;

			&:hover,
			&:focus {
				color: $dark-orange;
				border-color: $dark-orange;
				background-color: $white;
			}
		}


		& + input[type="submit"] {
			margin-left: pxToRem(10);
		}
	}

	textarea {
		resize: none;
		width: 100%;
		min-height: pxToRem(260);
		border: 1px solid $white-three;
		padding: pxToRem(13) pxToRem(16) pxToRem(13) pxToRem(16);
        font-size: 0.875em; //14
        font-weight: 300;
        color: $black;

		&:disabled {
			opacity: 0.5;
		}
	}

	fieldset {
		border: none;
		padding: 0;
		margin: 0;
	}

	&__field {
		max-width: pxToRem(420);
		padding: pxToRem(5) 0;
		@extend %clearfix;

		&-right {
			text-align: right;
		}

		&-wrapper {
			position: relative;
			@extend %clearfix;
		}

		&-select {
			.form__field-wrapper {
				position: relative;
				background-color: $white;		

				&:after {
					content: url(../mysource_files/arrow-down-dark.svg);
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					display: block;
					width: pxToRem(13);
					height: pxToRem(50);
					line-height: pxToRem(50);
				}
			}

			select {
		        font-size: 0.875em; //14
		        font-weight: 300;
		        color: $greyish-brown-two;
				background-color: transparent;
				padding: pxToRem(13) pxToRem(60) pxToRem(13) 0;
				width: 100%;
				border-radius: 0;
				border-width: 0 0 2px 0;
				border-style: dotted;
				border-color: $warm-grey-three;
				border-image-source: url(../mysource_files/border.png);
				border-image-slice: 33% 33%;
				border-image-repeat: round;


				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				position: relative;
				z-index: 1;

				&::-ms-expand {
				    display: none; /* remove default arrow in IE 10 and 11 */
				}

				&:disabled {
					opacity: 0.5;
				}
			}

			&-full {
				.form__field-wrapper {
					&:after {
						right: pxToRem(16);
					}
				}

				select {
					border-image: none;
					border: 1px solid $white-three;
					padding: pxToRem(13) pxToRem(36) pxToRem(13) pxToRem(16);
				}
			}

			&-small {
				.form__field-wrapper {
					max-width: pxToRem(100);
				}
			}

			&.disabled {
				.form__field-wrapper {
					&:after {
						opacity: 0.5;
					}
				}
			}
		}

		&.error {
			.form__error {
				display: block;
			}

			input[type="text"],
			input[type="search"],
			input[type="email"],
			input[type="tel"],
			input[type="password"],
			textarea {
				border: 2px solid $rust-red;
			}

			select {
				border-bottom: 2px solid $rust-red;
				border-image: none;
			}

			.form__field-wrapper {
				>input[type="checkbox"],
				>input[type="radio"] {
					& + label {
						&:before {
							border: 1px solid $rust-red;
						}
					}
				}

			}

			&.form__field-select-full {
				select {
					border: 2px solid $rust-red;
				}
			}

		}

		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;

			li {
				padding: 0;
				margin: 0;
				position: relative;

				&:after {
					display: none;
				}
			}
		}
	}

	.sq-form-question {
		max-width: pxToRem(420);
		padding: pxToRem(5) 0;

		&-answer {
			ul {
				list-style-type: none;
				padding: 0;
				margin: 0;

				li {
					padding: 0;
					margin: 0;

					&:after {
						display: none;
					}
				}
			}

		}

		label {
			&.sq-form-question-title {
				@extend .visuallyhidden;
			}
		}

		.sq-form-required-field {
			border: none;
			text-decoration: none;
			color: $rust-red;
		}

		&-error {
			.form__error {
				display: block;
			}

			input[type="text"],
			input[type="search"],
			input[type="email"],
			input[type="tel"],
			input[type="password"],
			textarea,
			select {
				border: 2px solid $rust-red;
			}

			input[type="checkbox"],
			input[type="radio"] {
				& + label {
					&:before {
						border: 1px solid $rust-red;
					}
				}
			}
		}

		.sq-form-error {
			font-size: 0.875em; //14
			font-weight: 300;
			color: $rust-red;
			padding: pxToRem(10) 0;

			&:before {
				content: '';
				width: pxToRem(6);
				height: pxToRem(6);
				border-radius: 100%;
				background-color: $rust-red;
				margin-right: pxToRem(10);
				display: inline-block;
				position: relative;
				top: pxToRem(-2);
			}

			&-technical {
				font-size: 1; //18
				font-weight: 500;
				display: none;
			}

			a[href] {
				color: $rust-red;
				@include font-smoothing-reset();
				text-decoration: underline;

				&:focus,
				&:hover {
					text-decoration: none;
				}
			}
		}

		&.sq-form-question-select,
		&.sq-form-question-country {
			.sq-form-question-answer {
				position: relative;
				background-color: $white;		

				&:after {
					content: url(../mysource_files/arrow-down-dark.svg);
					position: absolute;
					top: 0;
					bottom: 0;
					right: pxToRem(16);
					display: block;
					width: pxToRem(13);
					height: pxToRem(50);
					line-height: pxToRem(50);
				}
			}

			select {
		        font-size: 0.875em; //14
		        font-weight: 300;
		        color: $greyish-brown-two;
				background-color: transparent;
				padding: pxToRem(13) pxToRem(60) pxToRem(13) 0;
				width: 100%;
				border-radius: 0;
				border: 1px solid $white-three;
				padding: pxToRem(13) pxToRem(36) pxToRem(13) pxToRem(16);


				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				position: relative;
				z-index: 1;

				&::-ms-expand {
				    display: none; /* remove default arrow in IE 10 and 11 */
				}
			}
		}
	}
}
.payment-gateway-transaction-error {
	color: $rust-red;
}