/* Module: S */
.events-list {
	padding-bottom: pxToRem(20);
	
	+ .pagination {
		border-top: 1px solid $white-five;
	}

	&__item {
		padding: pxToRem(20) 0;
		border-top: 1px solid $white-five;
		position: relative;
		color: $greyish-brown-two;

		&:first-child {
			border-top: none;
		}
	}

	a {
		text-decoration: none;
		@include font-smoothing();
	}

	&__date {
		position: absolute;
		left: 0;
		top: pxToRem(20);
		padding-right: pxToRem(40);
	}

	&__month {
		font-size: 0.875em; //14
  		font-weight: 300;
	}

	&__day {
		font-size: 2.250em; //36
  		font-weight: 300;
	}

	&__year {
		font-size: 0.875em; //14
  		font-weight: 300;
	}

	&__title {
		font-size: 1.125em; //18
		line-height: 1.44;
  		font-weight: 300;
		@include sq-transition(color 0.3s);	
		padding: 0 0 pxToRem(14) pxToRem(80);

		a {
			display: block;
			color: $greyish-brown-two;

			&:after {
				content: url(../mysource_files/arrow-orange.svg);
				width: pxToRem(15);
				height: pxToRem(11);
				margin-left: pxToRem(10);
				display: inline-block;
			}

			&:hover,
			&:focus {
				color: $dark-orange;
			}
		}
	}

	&__info {
		font-size: 0.875em; //14
		font-weight: 300;
		line-height: 1.64;
		@include sq-transition(color 0.3s);	
		padding-left: pxToRem(80);

		p {
			margin: 0;
			padding: 0;
		}

		a {
			font-weight: 500;
			color: $dark-orange;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	&__text {
		font-size: 1em; //16
		font-weight: 300;
		line-height: 1.63;
		color: $greyish-brown-two;
		padding-top: pxToRem(24);
		display: none;
	}
}