/* Module: S */
.downloads {
	&__count {
		padding: pxToRem(26) pxToRem(180) pxToRem(20) 0;
	}

	&__image {
		width: 15%;
	}

	&__info {
		width: 45%;
	}

	&__description {
		width: 40%;
	}

	&__pagination {
		padding: pxToRem(4) 0 pxToRem(24) 0;
	}

	&__tags {
		padding-bottom: pxToRem(28);
	}

	&-simple {
		.downloads {
			&__info {
				width: 50%;
			}

			&__description {
				width: 50%;
			}
		}
	}
}