/* Module: S */
.publication {
	padding: 0 0 pxToRem(46) 0;
    @include sq-flex-box;
    @include sq-flex-direction(row);

	&__image {
		max-width: pxToRem(256);
		min-width: pxToRem(256);
		padding: 0 pxToRem(40) 0 0;

		img {
			max-height: none;
			width: pxToRem(216);
		}
	}

	&__title {
		font-size: 2.000em; //32
		line-height: 1.41;
		padding-bottom: pxToRem(20);
	}

	&__details {
		ul {
		    @include column-count(2);
		}

		li {
			margin: 0;
			padding: pxToRem(4) pxToRem(40) pxToRem(4) 0;
		}
	}

	&__links {
		margin-top: pxToRem(30);
		padding: pxToRem(16) 0 0 0;

		ul {
			margin: 0 pxToRem(-35);
		}

		li {
			float: left;
			font-size: 1em; //16
			padding: 0 pxToRem(35);
		}

		span {
			padding: pxToRem(11) 0;
		}

		a {
	        padding: pxToRem(8) pxToRem(16);
		}
	}

	&__note {
		margin-top: pxToRem(28);
	}
}
