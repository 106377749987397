/* Module: S */
.tabs {
	margin: pxToRem(30) 0;

	&__title {
	    position: relative;

		button {
		    padding: pxToRem(25) pxToRem(45) pxToRem(25) pxToRem(20);
	        
	        &:before,
	        &:after {
	            top: pxToRem(37);  
	        }
		}
	}

	&__text {
		padding: pxToRem(30) pxToRem(20);
	}
}