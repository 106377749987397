/* Module: S */
.events {
	h2 {
		padding-left: 0;
		padding-right: 0;
		padding-top: pxToRem(40);
	}

	&__item {
		min-height: pxToRem(106);
	}

	&__date {
		flex-basis: 17.5%;
		&:after {
			bottom: pxToRem(2);
		}
	}

	&__day {
		font-size: 3.063em; //49
		line-height: 1;
	}

	&__title {
		flex-basis: 50%;
		padding-right: 1.875rem;
	}

	&__info {
		flex-basis: 32.5%;
		margin: 0;
	}
}