/* Module: S */
.news {
	margin: 0 pxToRem(-16);

	h2 {
		padding-left: pxToRem(16);
		padding-right: pxToRem(16);
		padding-top: pxToRem(40);
	}

	.news__section-title {
		padding-left: 0;
	}

	&__item {
		padding: pxToRem(16) pxToRem(16) pxToRem(25) pxToRem(16);
		width: 50%;
	}

	&__date {
		font-size: 0.875em; //14
  		font-weight: 500;
  		line-height: 1.79;
	}

	&__title {
		font-size: 1.250em; //20
		line-height: 1.35;
	}
}

.news-home {

	.news__item {
		margin: 0;
		border: none;
		padding: 1rem 1rem 1.5625rem 1rem;
		width: 50%;
	}
}

.news-type-2,
.news-landing {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: pxToRem(71);
	padding-left: pxToRem(6);
	padding-right: pxToRem(6);

	h2 {
		flex-basis: 100%;
    padding-top: pxToRem(19);
    padding-bottom: pxToRem(42);
		padding-left: pxToRem(10);
		font-size: pxToRem(36);
    line-height: pxToRem(42);
	}

	.news__item-wrapper {
		flex-basis: 50%;
		margin-bottom: pxToRem(20);
		padding-right: pxToRem(10);
		padding-left: pxToRem(10);
	}

	.news__item {
		margin-right: 0;
		border: 1px solid #E3E3E3;
		padding: 0;
		width: 100%;
		min-height: 0;
		height: 100%;
	}

	a {
		display: block;
		height: 100%;

		&:after {
			position: absolute;
			right: pxToRem(20);
			bottom: pxToRem(18);
			margin: 0;
		}
	}

	.news__info {
		padding-bottom: pxToRem(40);
	}
}

.news-type-2 {
	margin-bottom: 0;

	.tile__title,
	.tile__links {
		flex-basis: 100%;
		margin: 0 pxToRem(10) pxToRem(20);
	}

	.tile__links {
		margin-bottom: 0;
		padding-top: pxToRem(28);

		a::after {
			position: relative;
			right: auto;
			bottom: auto;
			margin-left: pxToRem(10);
		}
	}
}