/* Module: S */
.review {
    @include sq-flex-box;
    @include sq-flex-direction(row);
    @include sq-flex-wrap(wrap);
    margin: 0 pxToRem(-20);

	&__column {
		width: 50%;
		padding: pxToRem(20);
		border-top: none;

		&:first-child {
			padding-top: pxToRem(20);
		}

		&-large {
			width: 100%;
		}
	}

	&__title {
		h3 {
			padding-right: pxToRem(34);
			width: auto;
		}

		a {
			float: left;
			width: auto;
		}
	}

	&__button {
		padding-top: pxToRem(36);
	}
}