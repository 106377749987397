/* Module: S */
.link-tiles {
	margin-left: pxToRem(-16);
	margin-right: pxToRem(-16);

	&__section-title {
        margin: pxToRem(34) 0 pxToRem(11);
        font-size: pxToRem(36);
        line-height: pxToRem(44);
    }

	&__item {
		padding: pxToRem(15) pxToRem(16);
		width: 50%;

        &:nth-child(2n+1) {
            clear: left;
        }
	}
}