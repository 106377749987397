/* Module: S */
body.terms-of-use_open {
  height: 100vh;
  overflow-y: hidden;
  // position: fixed;
}

.terms-of-use {
  position: relative;
  height: 100%;

  &_wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 10;
  }

  &_logo {
    display: block;
    margin: 16px auto 0;
  }

  &_box {
    position: absolute;
    border: 1px solid $dark-orange;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &_content {
    padding: 16px 24px;
  }

  &_heading {
    padding-top: 10px;
    text-align: center;
    font-weight: 500;
  }

  &_ctas {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;

    button {
      margin-top: 24px;
      background-color: $dark-orange;
      border: 1px solid $dark-orange;
      padding: 10px 20px;
      color: $white;

      &:hover,
      &:focus {
        background-color: $white;
        color: $dark-orange;
        cursor: pointer;
      }
    }
  }
}
