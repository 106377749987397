/* Module: S */
.banner-carousel {
	position: relative;
	@extend %clearfix;

	&__item {
		width: 100%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		float: left;
		position: relative;
		overflow: hidden;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba($black-two, 0.8);
		}
	}

	&__info {
		padding: pxToRem(40) $padding pxToRem(80) $padding;
		color: $white;
		position: relative;
		@include font-smoothing;
        max-width: $max-width;
        margin: 0 auto;   
	}

	&__title {
		font-size: 1.875em; //30
		font-weight: 300;
		line-height: 1.33;
		max-width: pxToRem(600);
	}

	&__text {
		font-size: 1em; //16
		line-height: 1.63;
		padding-top: pxToRem(18);
		max-width: pxToRem(400);
	}

	&__link {
		padding-top: pxToRem(28);
		max-width: pxToRem(600);

		a {
			display: inline-block;
			padding: pxToRem(12) pxToRem(25) pxToRem(12) pxToRem(20);
			color: $pastel-orange;
			background-color: transparent;
			border: 1px solid $pastel-orange;
			text-decoration: none;
			font-size: 0.938em; //15

			&:before {
				content: url(../mysource_files/arrow-orange-2.svg);
				width: pxToRem(15);
				height: pxToRem(11);
				margin-right: pxToRem(15);
				display: inline-block;
			}

			&:hover,
			&:focus {
				background-color: $dark-orange;
				border: 1px solid $dark-orange;
				color: $white;

				&:before {
					content: url(../mysource_files/arrow-white.svg);
				}
			}
		}
	}

	.slick {
		&-controls {
			position: relative;
			z-index: 1;
      max-width: $max-width;
      bottom: pxToRem(10);
      margin: pxToRem(-24) auto 0 auto;
      text-align: right;
      padding: 0 pxToRem(16);
		}

		&-play {
			display: inline-block;

			button {
				-webkit-appearance: none;
				text-indent: -9999px;
				width: pxToRem(18);
				height: pxToRem(18);
				background-color: transparent;
				border: none;
				position: relative;
				padding: 0;

				&:after {
					content: '';
					position: absolute;
					top: pxToRem(4);
					left: pxToRem(5);
					width: pxToRem(8);
					height: pxToRem(9);
					border-left: 2px solid $white;
					border-right: 2px solid $white;
				}

				&.active {
					&:after {
						top: pxToRem(3);
						width: 0;
						height: 0;
						border-right: none;
						border-top: 6px solid transparent;
						border-bottom: 6px solid transparent;
						border-left: 8px solid $white;
					}
				}
			}
		}

		&-dots {
			padding: 0;
			margin: 0;
			display: inline-block;

			li {
				& + li {
					margin: 0;

				}

				&.slick-active {
					button {
						&:after {
							background-color: $white;
						}
					}
				}
			}

			button {
				-webkit-appearance: none;
				text-indent: -9999px;
				width: pxToRem(18);
				height: pxToRem(18);
				background-color: transparent;
				border: none;
				position: relative;
				padding: 0;

				&:after {
					content: '';
					position: absolute;
					top: pxToRem(4);
					left: pxToRem(4);
					width: pxToRem(10);
					height: pxToRem(10);
					background-color: transparent;
					border: 1px solid $white;
					border-radius: 100%;
				}
			}
		}
	}
}

.banner-carousel-three-up {
	margin: pxToRem(-74) 0 0;
	padding: 0 0 pxToRem(60);

	&__wrapper {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: pxToRem(74);
			left: 0;
			right: 0;
			bottom: 0;
			background-color: $white-four;
		}
	}

	&__item {
		padding: 0 pxToRem(10);
	}

	&__content {
		display: flex;
		flex-direction: column;
		background-color: white;
		text-decoration: none;

		&:hover,
		&:focus {

			.banner-carousel-three-up__image {
				@include sq-transform(scale(1.1));
			}

			.banner-carousel-three-up__title,
			.banner-carousel-three-up__summary {
				color: $dark-orange;
			}
		}
	}

	&__image-wrapper {
		margin: 0 0 pxToRem(22);
		height: pxToRem(134);
		min-height: pxToRem(134);
		overflow: hidden;
	}

	&__image {
		height: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		@include sq-transform(scale(1));
		@include sq-transition(transform 0.3s);
	}

	&__title {
		margin: 0 0 pxToRem(8);
		padding: 0 pxToRem(18);
		font-size: pxToRem(20);
		line-height: pxToRem(28);
		font-weight: 500;
		color: $black;
		@include sq-transition(color 0.3s);
	}

	&__summary {
		flex-grow: 1;
		margin: 0 0 pxToRem(8);
		padding: 0 pxToRem(18);
		line-height: pxToRem(24);
		font-weight: 300;
		color: $black;
		@include sq-transition(color 0.3s);
	}

	&__icon {
		padding: 0 pxToRem(18) pxToRem(16);
		text-align: right;

		&:before {
			content: url(../mysource_files/arrow-orange.svg);
			display: inline-block;
			width: pxToRem(29);
			height: auto;
		}
	}

	.slick {
		&-controls {
			position: relative;
			z-index: 1;
			margin: pxToRem(39) auto 0;
			text-align: center;
		}

		&-play {
			display: inline-block;
			margin-right: pxToRem(11);

			button {
				-webkit-appearance: none;
				text-indent: -9999px;
				width: pxToRem(18);
				height: pxToRem(18);
				background-color: transparent;
				border: none;
				position: relative;
				padding: 0;

				&:after {
					content: '';
					position: absolute;
					top: pxToRem(4);
					left: pxToRem(5);
					width: pxToRem(11);
					height: pxToRem(14);
					border-left: 4px solid $black;
					border-right: 4px solid $black;
				}

				&.active {
					&:after {
						top: pxToRem(3);
						width: 0;
						height: 0;
						border-right: none;
						border-top: 8px solid transparent;
						border-bottom: 8px solid transparent;
						border-left: 13px solid $black;
					}
				}
			}
		}

		&-dots {
			display: inline-block;
			padding: 0;
			margin: 0;

			li {
				margin: 0 pxToRem(13) 0 0;

				&:last-child {
					margin-right: 0;
				}

				&.slick-active {
					button {
						&:after {
							background-color: $dark-orange;
						}
					}
				}
			}

			button {
				-webkit-appearance: none;
				text-indent: -9999px;
				width: pxToRem(18);
				height: pxToRem(18);
				background-color: transparent;
				border: none;
				position: relative;
				padding: 0;

				&:after {
					content: '';
					position: absolute;
					top: pxToRem(4);
					left: pxToRem(2);
					width: pxToRem(14);
					height: pxToRem(14);
					background-color: #c4c4c4;
					border-radius: 100%;
				}
			}
		}
	}
}