/* Module: S */
.downloads {
	border: 1px solid $white-seven;
	background-color: $white;
	margin: pxToRem(20) 0;

	&__count {
		font-size: 0.875em; //14
		font-weight: 300;
		line-height: 1.64;
		color: $greyish-brown-four;
		padding: pxToRem(20) 0;
	}

	&__results {
		border-bottom: 1px solid $white-seven;	
		padding: 0 pxToRem(24);	
	}

	&__item {
		padding: pxToRem(20) pxToRem(24);
		border-bottom: 1px solid $white-seven;
		@extend %clearfix;

		&:last-child {
			border-bottom: none;
		}
	}

	&__tags {
		padding-bottom: pxToRem(32);

		ul {
			list-style-type: none;
			padding: 0;
			margin: pxToRem(-5);
	        @include sq-flex-box;
	        @include sq-flex-direction(row);
	        @include sq-flex-wrap(wrap);
		}

		li {
			margin: 0;
			padding: pxToRem(5);
			position: relative;
			font-size: 0.875em; //14
			font-weight: 300;
			line-height: 1.7;
			color: $greyish-brown-four;

			> span {
				display: block;
				background-color: $white-three;
				border-radius: pxToRem(24);
				padding: pxToRem(6) pxToRem(36) pxToRem(6) pxToRem(15);
			}	

			a {
				position: absolute;
				top: pxToRem(5);
				right: pxToRem(5);
				bottom: pxToRem(5);
				width: pxToRem(35);
			    padding: pxToRem(13);
			    outline: none;
			    text-decoration: none;

			    svg {
			    	fill: $greyish-brown-two;
			    	width: pxToRem(9);
			    	height: pxToRem(9);
			    	display: block;
			    }

			    &:hover,
			    &:focus {
			    	svg {
			    		fill: $dark-orange;
			    	}
			    }
			}
		}
	}

	&__image {
		display: none;
	}

	&__title {
		a {
			font-size: 1.125em; //18
			font-weight: 300;
			line-height: 1.44;
			color: $dark-orange;
			text-decoration: none;
  			display: inline-block;
			@include sq-transition(color 0.3s);	
			@include font-smoothing;
			@include break-word;

			&:hover,
			&:focus {
				color: $greyish-brown-two;
			}

			&:after {
				content: url(../mysource_files/arrow-orange.svg);
				width: pxToRem(15);
				height: pxToRem(11);
				margin-left: pxToRem(10);
				display: inline-block;
			}
		}
	}

	&__sub-title {
		a {
			font-size: 0.875em; //14
			font-weight: 300;
			line-height: 1.44;
			color: $dark-orange;
			text-decoration: none;
  			display: inline-block;
			@include sq-transition(color 0.3s);	
  			@include font-smoothing;

			&:hover,
			&:focus {
				color: $greyish-brown-two;
			}

			&:after {
				content: url(../mysource_files/arrow-orange.svg);
				width: pxToRem(13);
				height: pxToRem(10);
				margin-left: pxToRem(8);
				display: inline-block;
			}
		}
	}

	&__links {
		ul {
			list-style-type: none;
			margin: 0;
			padding: pxToRem(10) 0 0 0;
		}

		li {
			margin: 0;
			padding: 0;			
		}

		a {
			color: $dark-orange;
			font-size: 0.875em; //14
			line-height: 1.64;
  			font-weight: 300;
  			@include font-smoothing;
  			display: inline-block;
			@include sq-transition(color 0.3s);	
			text-decoration: none;

			&:hover,
			&:focus {
				color: $greyish-brown-two;
			}

			&:after {
				content: url(../mysource_files/arrow-orange.svg);
				width: pxToRem(11);
				height: pxToRem(8);
				margin-left: pxToRem(8);
				display: inline-block;
			}
		}
	}

	&__details {
		ul {
			list-style-type: none;
			margin: 0;
			padding: pxToRem(10) 0 0 0;
		}

		li {
			margin: 0;
			padding: 0;	
			font-size: 0.875em; //14
			line-height: 1.64;
  			font-weight: 300;	
  			color: $greyish-brown-four;	
		}

		a {
			color: $dark-orange;
  			@include font-smoothing;
  			display: inline-block;
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}

		span {
			display: inline-block;

			& + span {
				padding-left: pxToRem(20);
				position: relative;

				&:after {
					content: '';
					position: absolute;
					left: pxToRem(8);
					top: pxToRem(4);
					bottom: pxToRem(4);
					width: 1px;
					background-color: $white-five;
				}
			}
		}
	}

	&__description {
		padding: pxToRem(15) 0 pxToRem(5) 0;

		button {
		    padding: 0;
		    border: none;
		    border-radius: 0;
		    background-color: transparent;
		    -webkit-appearance: none;
		    text-align: left;
		    font-size: 1em; //16
		    font-weight: 300;
  			line-height: 1.38;
		    @include sq-transition(color .3s);   
		    color: $greyish-brown-three;
  			@include font-smoothing;
  			min-width: pxToRem(62);
  			position: relative;

			&:hover,
			&:focus {
				color: $dark-orange;
				outline: none;
			}

			svg {
				position: absolute;
				top: pxToRem(4);
				right: 0;
				width: pxToRem(13);
				height: pxToRem(12);
				margin-left: pxToRem(12);
				display: inline-block;
		    	@include sq-transition(transform .3s);   
			}

			&.active {
				svg {
					@include sq-transform(rotate(180deg));
				}
			}
		}
	}

	&__text {
		font-size: 0.875em; //14
		line-height: 1.64;
		font-weight: 300;
		color: $greyish-brown-four;
		padding-top: pxToRem(15);
		display: none;
		@include break-word;

		&.active {
			display: block;
		}

		p {
			margin: 0;
			padding: 0;
		}
	}

	&__all {
		padding: pxToRem(30) pxToRem(24);

		a {
			font-size: 1.125em; //18
			font-weight: 300;
			line-height: 1.39;
			display: inline-block;
			color: $dark-orange;
  			@include font-smoothing;
  			text-decoration: none;

			&:hover,
			&:focus {
				color: $greyish-brown-two;
			}

			&:after {
				content: url(../mysource_files/arrow-orange.svg);
				width: pxToRem(15);
				height: pxToRem(11);
				margin-left: pxToRem(10);
				display: inline-block;
			}
		}
	}
}