/* Module: S */
.cart {
	position: relative;

	&__button {
		&-top {
			display: block;
			position: absolute;
			top: pxToRem(52);
			right: 0;
		}
	}

	&__image {
		display: block;
		float: left;
		padding-right: pxToRem(24);

		img {
			width: pxToRem(100);
			display: block;
		}

	}

	&__discount {
		padding-top: pxToRem(4);
		padding-right: 0;
		position: relative;
		bottom: auto;
		left: auto;
	}

	&__remove {
		position: relative;
		top: auto;
		right: auto;
		padding-top: pxToRem(16);

		a {
			display: block;
			width: auto;
			height: auto;
			text-indent: 0;
			font-size: 1em; //16
			font-weight: 300;
			line-height: 1.5;
			color: $dark-orange;
			text-decoration: none;
			@include font-smoothing();	
			outline: none;		

			&:hover,
			&:focus {
				text-decoration: underline;
			}

			&:before {
				display: none;
			}

		}
	}

	&__price {
		font-size: 1.125em; //18
		line-height: 1.33;
		padding-top: 0;
		padding-left: 0;
		width: 100%;
		text-align: left;
		position: relative;
		top: auto;
		right: auto;
	}

	&__quantity {
		padding-top: 0;
		position: relative;
		bottom: auto;
		top: pxToRem(-10);
		left: auto;
		width: 100%;
	}

	&__total {
		margin-left: 0;
		text-align: left;
		padding-top: 0;

		p {
			font-weight: 300;

			&.discount {
				text-decoration: line-through;
				padding-bottom: pxToRem(12);
			}	
		}
	}

	&__grand-total {
		padding: pxToRem(20) 0 pxToRem(40) 0;
		text-align: right;

		p {
			float: none;
			display: inline-block;
			width: auto;


			&:first-child {
				float: none;
				padding-right: pxToRem(30);
			}

			&.note {
				width: 100%;
				padding-top: pxToRem(10);
			}
		}
	}


	table {
		margin: pxToRem(26) 0;

        thead {
			display: table-header-group;

            tr {
                border-top: 1px solid $white-seven;

                &:first-child {
                    border-top: none;
                }
            }

            th {
	            border: 1px solid $white-seven;
	            font-size: 0.875em; //14
	            font-weight: 500;
	            line-height: 1.6;
	            padding: pxToRem(18) pxToRem(20);
	            text-align: left;
                color: $white;
                @include font-smoothing;
                background-color: $greyish-brown-six;
                border: none;
                border-left: 1px solid $white-seven;
                border-right: 1px solid $white-seven;

                &:first-child {
                    border-left: none;
                }

                &:last-child {
                    border-right: none;
                }
            }
        }

		tr {
			display: table-row;
		}

		td {
			display: table-cell;
            border: 1px solid $white-seven;
            padding: pxToRem(30) pxToRem(20);
            vertical-align: top;

            &:first-child {
            	border-left: none;
            }

            &:last-child {
            	border-right: none;
            }
		}
	}


}