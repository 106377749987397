/* Module: S */
.filters {
	&__header {
	    font-size: 1.750em; //28
	    line-height: 1.43;
		@include font-smoothing-reset();
		padding-top: 0;

	    button {
	    	display: none;
	    }

	    span {
	    	opacity: 1;
	    }
	}

	&__main {
		display: block;
		padding-top: pxToRem(10);
	}

	&__title {
		button {
			width: auto;
		}
	}

	&__item {
		border-bottom: none;
	}
}