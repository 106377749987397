/* Module: S */
.subscribe {
	padding: pxToRem(10) 0 pxToRem(20) 0;
	@extend %clearfix;

	&__info {
		h4 {
			font-size: 24px;
			font-weight: normal;
		}
	}

	.form {
		@include placeholder-color($white);
		padding: 0;

		input[type="text"] {
			color: $white;
			border: 1px solid $white;
			background-color: transparent;
		}

		&__field {
			max-width: 100%;

			>input[type="submit"] {
				float: right;
				border: none;
				background-color: transparent;
				color: $white;
				font-weight: 300;
				padding: pxToRem(12) pxToRem(25) pxToRem(12) pxToRem(0);
				margin-top: pxToRem(10);

				&:hover,
				&:focus {
					color: $pastel-orange;
					background-color: transparent;
				}
			}

			&:last-child {
				position: relative;

				&:after {
					content: url(../mysource_files/arrow-white.svg);
					width: pxToRem(15);
					height: pxToRem(11);
					margin-top: pxToRem(27);
					display: inline-block;
					position: absolute;
					right: 0;
				}
			}
		}

		&__error {
			color: $white;

			&:before {
				background-color: $white;
			}
		}
	}
}