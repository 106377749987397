/* Module: S */
body {
	&.feedback-open {
		overflow: initial;
	}
}

.feedback {
	display: block;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
    z-index: 10;

	&__toggle {
		position: absolute;
		bottom: pxToRem(20);
		right: pxToRem(20);
	}

	&__hide {
		display: none;
	}

	&__widget {
		position: absolute;
		top: auto;
		left: auto;
		bottom: pxToRem(100);
		right: pxToRem(40);
		width: pxToRem(406);
		padding: pxToRem(40) pxToRem(40) pxToRem(40) pxToRem(40);
		box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.09);
		max-height: calc(100vh - 7.778rem);
	}

	&__title {
		font-size: 1.500em; //24
		line-height: 1.46;
	}
}