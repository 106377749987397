/* Module: S */
.toc {

	&__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: pxToRem(23) 0 pxToRem(6);

    .search-full-width {
      margin: 0;
      width: pxToRem(400);
    }
  }

	+ .content {
		>h1,
		>h2,
		>h3 {
			&:first-child {
				padding-right: pxToRem(200);
			}
		}

		>div {
			&:first-child {
				>h1,
				>h2,
				>h3 {
					&:first-child {
						padding-right: pxToRem(200);
					}
				}
			}
		}
	}

	&__toggle {
		margin: 0;
	}

	&__main {
		&:after {
			display: none;
		}

		> ul {
			border: 1px solid $white-five;
			border-top: 0;
			padding: 0 pxToRem(26);
		}
	}

	&__item {
		font-size: 1.125em; //18
		line-height: 1.38;
		padding: pxToRem(14) 0;
	}

	&:not(.toc-plain):not(.toc-left) {
		margin-top: 0;
		padding-bottom: 0;

		.toc {
			&__toggle {
				margin: 0;
				position: absolute;
				right: pxToRem(40);
				top: pxToRem(82);

				&:after {
					display: none;
				}

				button {
			    	color: $dark-orange;
					font-size: 1.125em; //18
					font-weight: 500;
					padding: pxToRem(17) 0 pxToRem(17) pxToRem(48);
					width: auto;

				    &:hover,
				    &:focus {
		            	color: $greyish-brown-eight;
				    }

				    &:before {
				    	content: url(../mysource_files/menu-orange.svg);
				    	top: pxToRem(18);
				    }

					svg {
						display: none;
					}
				}
			}

			&__close {
				display: block;
			}

			&__main {
				background-color: $white;
				bottom: 0;
				box-shadow: pxToRem(-8) 0 pxToRem(15) 0 rgba(0, 0, 0, 0.11);
				display: block;
				min-width: pxToRem(900);
				overflow-y: auto;
				padding: pxToRem(100) pxToRem(90);
				position: fixed;
				right: -85%;
				top: 0;
				visibility: hidden;
				width: 45%;
				z-index: 11;

				> ul {
					border: 1px solid $white-five;
				}

				&.active {
					right: 0;
					visibility: visible;
				}
			}

			&__title {
				display: block;
			}
		}
	}

	&.toc-left {
		margin-top: 0;
		padding-bottom: 0;

    .toc {
      &__toggle {
        margin: 0;

        &:after {
          display: none;
        }

        button {
          padding-right: pxToRem(14);
          width: auto;
          @include sq-transition(color 0.3s 0s);

          svg {
            display: none;
          }
        }
      }

      &__close {
        display: block;
      }

      &__main {
        background-color: $white;
        bottom: 0;
        box-shadow: pxToRem(-8) 0 pxToRem(15) 0 rgba(0, 0, 0, 0.11);
        display: block;
        min-width: pxToRem(850);
        overflow-y: auto;
        border: none;
        padding: pxToRem(100) pxToRem(90);
        position: fixed;
        right: -85%;
        top: 0;
        visibility: hidden;
        width: 45%;
        z-index: 11;

        > ul {
          border: 1px solid $white-five;
        }

        &.active {
          right: 0;
          visibility: visible;
        }
      }

      &__title {
        display: block;
      }

      &__item {
        &.section-title {
          padding-top: pxToRem(39);
        }
      }
    }
	}
}