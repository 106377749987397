/* Module: S */
.form {
	padding: pxToRem(30) 0;

    &-side {
        padding-top: 0;
    }

    &-clear {
        padding-top: 0;
    }

    .padding {
        padding-top: pxToRem(30);
        padding-bottom: pxToRem(30);

        &-top {
            padding-top: pxToRem(30);
        }

        &-bottom {
            padding-bottom: pxToRem(30);
        }
    }
}