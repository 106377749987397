/* Module: S */
.link-list {
	max-width: pxToRem(474);

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	li {
		list-style-type: none;
		padding: pxToRem(18) 0;
		margin: 0;
		border-bottom: 1px solid $white-five;
		color: $greyish-brown-two;
		font-size: 1em; //16
		font-weight: 300;
		line-height: 1.38;
	}

	a {
		color: $dark-orange;
		@include font-smoothing();
		font-weight: 300;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	button {
		padding: pxToRem(13);
		margin: 0;
		width: pxToRem(40);
		height: pxToRem(40);
		text-align: center;
		background: transparent;
		border: none;
		-webkit-appearance: none;
		display: inline-block;
		position: absolute;
		right: pxToRem(-13);
		bottom: pxToRem(10);
		outline: none;

		svg {
			width: pxToRem(14);
		}

		&:hover,
		&:focus {
			svg {
				fill: $dark-orange;
			}
		}
	}

	&__more {
		padding: pxToRem(18) 0;
		border-bottom: 1px solid $white-five;

		a {
			@include sq-transition(color 0.3s);

			&:hover,
			&:focus {
				color: $greyish-brown-three;
				text-decoration: none;
			}

			&:after {
				content: url(../mysource_files/arrow-orange.svg);
				width: pxToRem(15);
				height: pxToRem(11);
				margin-left: pxToRem(10);
				display: inline-block;
				transition: all 0.7s;
			}

			&.expanded::after {
				transform: rotate(-90deg) translateX(-5px) translateY(-5px);
			}
			&.collapsed::after {
				transform: rotate(90deg) translateX(-5px) translateY(-5px);
			}
		}
	}

	&-dynamic {
		li {
			padding-right: pxToRem(32);
			position: relative;
		}
	}

	&-full {
		max-width: 100%;
	}
}
