/* Module: S */
.review {

	&__column {
		width: 30%;

		&-large {
			width: 40%;
		}
	}
}