/* Module: S */
.tile-homepage {
	margin: pxToRem(64) 0 pxToRem(6);

	&__image {
		margin-bottom: pxToRem(34);

		img {
			width: 100%;
			max-width: pxToRem(500);
			height: auto;
		}
	}

	&__title {
		a {
			font-size: pxToRem(27);
			text-decoration: none;
			@include sq-transition(color 0.3s 0s);

			&:hover,
			&:focus {
				color: $dark-orange;
			}
		}
	}

	&__text {
		margin: pxToRem(11) 0 0;

		p {
			margin: 0;
			padding: 0;
		}
	}

	&__links {
		padding-top: pxToRem(31);

		p {
			margin: 0;
			padding: 0;
			font-size: pxToRem(18);
			font-weight: 500;
		}

		ul {
			margin: pxToRem(17) 0 0;
			padding: 0;
			list-style-type: none;

		    li {
		    	margin: 0;
    			padding: 0;

    			a {
				    display: block;
				    padding: pxToRem(9) 0;
    				color: $dark-orange;
				    font-size: pxToRem(20);
				    line-height: pxToRem(24);
				    font-weight: 300;
    				text-decoration: none;
				    @include sq-transition(color 0.3s 0s);

				    &:hover,
				    &:focus {
				    	color: $greyish-brown-two;
				    }

				    &:after {
				    	content: url(../mysource_files/arrow-orange.svg);
					    width: pxToRem(14);
					    height: pxToRem(5);
					    margin-left: pxToRem(10);
					    display: inline-block;
				    }
    			}
		    }
		}
	}
}