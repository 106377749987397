/* Module: S */
.events {
	&__item {
		flex-wrap: nowrap;
		
		&:last-child {
			border-bottom: 1px solid rgba($white, 0.4);
		}
	}

	&__date {
		flex-basis: 20%;
	}

	&__title {
		flex-basis: 40%;
		padding-right: 2.5rem;
	}
	
	&__info {
		flex-basis: 40%;
	}
}