/* Module: S */
.color-block {
	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: pxToRem(53);
		padding-bottom: pxToRem(57);

		.color-block__text {
			flex-basis: pxToRem(670);
		}

		h2 {
			font-size: pxToRem(41);
			line-height: pxToRem(53);
		}

		p {
			margin: pxToRem(6) 0 0;
		}
	}

	.section__wrapper:before {
		display: none;
	}

	.section__wrapper:after {
		display: none;
	}
}