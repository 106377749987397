/*
--------------------
Content
--------------------
*/

h1 {
    font-size: 3.000em; //48
    line-height: 1.33;
    padding: pxToRem(36) 0 pxToRem(11);
}

h2 {
	font-size: 2.250em; //36
    padding: pxToRem(48) 0 pxToRem(15);
    line-height: 1.39;

    &.bookmark {
        &:after {
            width: pxToRem(14);
            height: pxToRem(20);
            margin-left: pxToRem(20);
        }

    }
}

h3 {
    font-size: 1.750em; //28
    padding: pxToRem(42) 0 pxToRem(10);
    line-height: 1.43;
}

h4 {
    font-size: 1.375em; //22
    padding: pxToRem(36) 0 pxToRem(5);
    line-height: 1.55;
}

h5 {
    font-size: 1.125em; //18
    padding: pxToRem(20) 0 pxToRem(5);
    line-height: 1.67;
}

h6 {
    font-size: 1em; //16
    padding: pxToRem(14) 0 pxToRem(5);
    line-height: 1.63;
}

hr {
    margin: pxToRem(24) pxToRem(-20);
}

.section {
    &-padding {
        padding: pxToRem(50) 0;
    }

    &-padding-top {
        padding: pxToRem(50) 0 0 0;
    }

    &-padding-bottom {
        padding: 0 0 pxToRem(50) 0;
    }

    &__wrapper {
        padding: pxToRem(40) $padding;
    }

    &-merged {
        .section {
            &__wrapper {
                padding-bottom: 0;
                margin-bottom: pxToRem(-40);
            }
        }
    }
    
    &-orange-top {
        &:before {
            height: pxToRem(320);
        }
    }

    &-blue {
        background-image: url(../mysource_files/blue-lookup-desktop.png);
        background-position: center right;
        background-size: contain; 
        h1 {
            font-size: pxToRem(41);
        } 
    }

    &__column {
        &-25 {
            padding: 0 0 pxToRem(30) 0;

            &:last-child {
                padding: pxToRem(30) 0 0 0;
            }
        }
    }
}

.intro {
    font-size: 1.500em; //24
    line-height: 1.58;
    padding: pxToRem(30) 0;

    p {
        padding: pxToRem(20) 0;
    }  
}

.content {
    img {
        margin: pxToRem(30) 0;
    }

    figure {
        padding: pxToRem(30) 0;

        figcaption {
            padding: pxToRem(20) 0;
        }
    }

    .right,
    .right-30,
    .right-50 {
        float: right;
        margin: pxToRem(10) 0 pxToRem(30) pxToRem(40);
        padding: 0;
        max-width: 40%;

        img {
            margin: 0;
        }

        figcaption {
            font-size: 0.875em; //14
            padding: pxToRem(10) 0 0 0;
            border-bottom: none;

            &:first-child {
                padding-bottom: pxToRem(10);
            }
        }

    }

    .right-30 {
        max-width: 30%;
    }

    .right-50 {
        max-width: 50%;
    }

    .left,
    .left-30,
    .left-50 {
        float: left;
        margin: pxToRem(10) pxToRem(40) pxToRem(30) 0;
        padding: 0;
        max-width: 40%;

        img {
            margin: 0;
        }

        figcaption {
            font-size: 0.875em; //14
            padding: pxToRem(10) 0 0 0;
            border-bottom: none;

            &:first-child {
                padding-bottom: pxToRem(10);
            }
        }

    }

    .left-30 {
        max-width: 30%;
    }

    .left-50 {
        max-width: 50%;
    }

    figure,
    div {
        &.right,
        &.right-30,
        &.right-50 {
            padding: pxToRem(10) 0 pxToRem(30) pxToRem(40);
            margin: 0;
        }

        &.left,
        &.left-30,
        &.left-50 {
            padding: pxToRem(10) pxToRem(40) pxToRem(30) 0;
            margin: 0;
        }
    }

    blockquote {
        padding: pxToRem(30) pxToRem(40) pxToRem(40) pxToRem(40);
        margin: pxToRem(30) 0;
    }

    table {
        th {
            padding: pxToRem(15) pxToRem(12);

        }

        td {
            padding: pxToRem(15) pxToRem(12);
        }
    }

    .padding {
        padding-top: pxToRem(30);
        padding-bottom: pxToRem(30);

        &-top {
            padding-top: pxToRem(30);
        }

        &-bottom {
            padding-bottom: pxToRem(30);
        }
    }

    &.mobile-padding {
        &-top {
            padding-top: 0;
        }
    }

    .table {
        margin: pxToRem(30) 0;
    }
}

/*
--------------------
Modules
--------------------
*/