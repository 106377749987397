/* Module: S */
.search {
    position: relative;

    .js-search-input {
        width: 100%;
        border: 1px solid #E3E3E3;
        padding: pxToRem(20) pxToRem(25);
    }

    input[type=submit] {
        border: none;
        border-radius: 0;
        background-color: transparent;
        background-image: url(../mysource_files/search.svg);
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 1.25rem;
        text-indent: -99999px;
        padding: 0;
        margin: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        padding: 0;
        width: 2.375rem;
        -webkit-transition: background-color 0.3s;
        -moz-transition: background-color 0.3s;
        transition: background-color 0.3s;
    }

    &__partial-list {
        position: absolute;
        top: pxToRem(49);
        left: pxToRem(-20);
        right: pxToRem(-20);
        background-color: $white;
        z-index: 9;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        ul {
            list-style-type: none;
            padding: pxToRem(10) 0 pxToRem(24) 0;
            margin: 0;
        }

        li {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        a {
            font-size: 0.875em; //14
            font-weight: 300;
            color: $greyish-brown-five;
            line-height: 1.2;
            text-decoration: none;
            padding: pxToRem(10) pxToRem(20);
            display: block;

            &:focus,
            &:hover {
                text-decoration: underline;
            }

            span {
                font-weight: 500;
            }
        }

    }

    &__more-results {
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        margin: 0 0 pxToRem(34) pxToRem(29);
        border: none;
        border-radius: pxToRem(4);
        padding: pxToRem(8) 0;
        font-size: pxToRem(14);
        line-height: pxToRem(17);
        font-weight: 400;
        text-align: left;
        background: none;
        cursor: pointer;
        text-decoration: none;
        color: $dark-orange;
        @include sq-transition(color 0.3s 0s);

        &:hover,
        &:focus {
            color: $greyish-brown-two;
        }

        &:after {
            content: url(../mysource_files/arrow-orange.svg);
            display: inline-block;
            margin-left: pxToRem(10);
            width: pxToRem(14);
            height: pxToRem(5);
            vertical-align: text-top;
            line-height: pxToRem(17);
        }
    }
}

#search-best-bets {
    background: $white-six;
    padding: 0px 20px;

    h4 {
        padding: 1.25rem 0 0.3125rem;
    }
}

.search-full-width {
    margin-top: pxToRem(56);

    &__title {
        margin: 0 0 pxToRem(30);
        padding: 0;
        font-size: pxToRem(28);
        line-height: pxToRem(37);
    }

    .search {

        &__partial-list {
            top: 100%;
            left: 0;
            right: 0;

            ul {
                padding-top: pxToRem(21);
                padding-bottom: pxToRem(17);
            }

            a {
                padding-left: pxToRem(29);
                font-size: pxToRem(16);
                line-height: pxToRem(19);
                font-weight: 400;

                span {
                    color: $dark-orange;
                }
            }
        }
    }
}