/* Module: S */
.download-links {

	ul {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-end;
		margin: pxToRem(12) 0 0;
		padding: 0;
		list-style: none;

		li {
			display: block;

			a {
				display: block;
				margin: 0 0 pxToRem(6) pxToRem(5);
				border: pxToRem(1) solid $greyish;
				padding: pxToRem(9) pxToRem(13) pxToRem(10);
				font-size: pxToRem(16);
				line-height: pxToRem(19);
				text-decoration: none;
				background-color: transparent;
				@include sq-transition(background-color 0.3s 0s, border-color 0.3s 0s);

				&:hover,
				&:focus {
					color: $dark-orange;
					border-color: white;
					background-color: white;
				}
			}

			a.download-icon,
			a.online-icon {
				display: flex;
				flex-direction: row;
				position: relative;
				padding: pxToRem(9) pxToRem(13) pxToRem(9) pxToRem(9);

				svg {
					margin: 0 pxToRem(6) 0 0;
					width: pxToRem(18);
					height: pxToRem(20);
				}
			}

			a.online-icon {
				svg {
					width: pxToRem(30);
				}
			}
		}
	}
}