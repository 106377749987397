/* Module: S */
.banner-carousel {
	&__info {
		padding: pxToRem(60) $padding pxToRem(120) $padding;
	}

	&__title {
		font-size: 3em; //48
	}

	&__text {
		padding-top: pxToRem(30);
	}

	&__link {
		padding-top: pxToRem(36);
	}

	.slick {
		&-controls {
			bottom: pxToRem(40);
	        padding: 0 pxToRem(15);
	    }

		&-play {			
			button {
				width: pxToRem(24);
				height: pxToRem(24);

				&:after {
					top: pxToRem(6);
					left: pxToRem(7);
					height: pxToRem(12); 
				}

				&.active {
					&:after {
						top: pxToRem(5);
						width: 0; 
						height: 0; 
						border-right: none;
						border-top: 7px solid transparent;
						border-bottom: 7px solid transparent;
						border-left: 10px solid $white;
					}
				}
			}		
		}

		&-dots {
			button {
				width: pxToRem(24);
				height: pxToRem(24);

				&:after {
					top: pxToRem(5);
					left: pxToRem(5);
					width: pxToRem(14);
					height: pxToRem(14);
					border: 2px solid $white;
				}
			}
		}
	}
}