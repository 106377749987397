/* Module: S */
.drive-standards {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	&__section-title {
		flex-basis: 100%;
	}

	&__block-one-title {
		padding: pxToRem(27) pxToRem(73) pxToRem(24);
	}

	&__block-one-links {
		border-right: none;
		flex-grow: 1;
	}

	&__block-one {
		display: flex;
		flex-direction: column;
		width: 65%;

		ul {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			padding-top: pxToRem(46);
			padding-left: pxToRem(73);

			li {
				margin: 0 0 pxToRem(9);
				padding-right: pxToRem(18);
				width: 50%;
			}
		}

		a.more-info {
			padding-left: pxToRem(73);
			padding-bottom: pxToRem(35);
		}
	}

	&__block-two {
		width: 35%;
		margin-top: 0;

		ul {
			li {
				a {
					position: relative;
					padding: pxToRem(50) pxToRem(74) pxToRem(49) pxToRem(54);

					&:after {
						position: absolute;
						top: 50%;
						margin-top: pxToRem(-10);
						right: pxToRem(47);
						width: pxToRem(18);
					}
				}
			}
		}
	}
}