/* Module: S */
.page-navigation {
    margin-top: pxToRem(60);
    padding: pxToRem(10) 0;

    &:after {
        display: none;
    }

    li {
        &:first-child {
            a {
                padding-left: pxToRem(30);
            }
        }

        &:last-child {
            a {
                padding-right: pxToRem(30);
            }
        }
    }

    a {
        padding: pxToRem(5) 0;

        span {
            &:first-child {
            	width: auto;
            }

            &:last-child {
                display: block;
                font-size: 1em; //16
                line-height: 1.63;
            }
        }
    }
}