/* Module: S */
.links-inline {

  &__container {
    margin: pxToRem(50) 0 0;
  }

  &__title {
    padding-bottom: pxToRem(22);
    font-size: pxToRem(28);
    line-height: pxToRem(37);
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      vertical-align: top;

      a {
        display: block;
        padding: pxToRem(8) 0;
        font-size: pxToRem(20);
        font-weight: 300;
        line-height: pxToRem(28);
        text-decoration: none;
        color: $dark-orange;
        @include sq-transition(color 0.3s 0s);

        &:hover,
        &:focus {
          color: $greyish-brown-two;
        }

        &:after {
          content: url(../mysource_files/arrow-orange.svg);
          display: inline-block;
          margin-left: pxToRem(10);
          width: pxToRem(14);
          height: pxToRem(5);
          vertical-align: text-top;
          line-height: pxToRem(21);
        }
      }
    }
  }
}