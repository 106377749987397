    /**
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * updated: {{date}}
 *
 * Table of Contents
 *
 * - Reset
 * - Utilities
 * - General
 * - Content
 * - Modules
    {{toc}}
 */

// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of
// a design cutup.
@import "boilerplate.scss";

/*
--------------------
Reset (normalize.css)
--------------------
*/

@import "normalize.scss";

// This import disabled by default due to rendering issues, particularly with IE where some text doesn't render at all
//@import "normalize-opentype.scss";

// Box size all the things
// https://squizmap.squiz.net/imp-bp/6400
// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// == Start box-sizing reset ==
*, *:before, *:after {
    -webkit-box-sizing: inherit;
       -moz-box-sizing: inherit;
            box-sizing: inherit;
}
html {
    @include sq-border-box;
}
// == End box-sizing reset ==

/*
--------------------
Utilities
--------------------
*/

@import "utilities.scss";

/*
--------------------
General
--------------------
*/

// Taken from HTML 5 BP
::-moz-selection {
    background: $content-selection-highlight;
    text-shadow: none;
}

::selection {
    background: $content-selection-highlight;
    text-shadow: none;
}

html,
body {
    height: 100%;
}
body {
    font-family: 'Roboto', sans-serif;
    font-size: $base-font-scale + em;
    line-height: 1.4;
    margin: 0;
    min-width: pxToRem(320);
    overflow-y: scroll;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
}

img {
    height: auto;
    max-width: 100%;
}

strong {
    font-weight: 500;
}

hr {
    border: none;
    border-top: 1px solid rgba($white, 0.4);
    margin: pxToRem(16) pxToRem(-20);

    .content & {
        border-color: $white-five; // For home page only
    }
}

// Reset fonts for relevant elements (taken from bootstrap)
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport{
    width: device-width;
}

// Print style that shouldn't belong to print.scss to avoid the css beautifier from killing
// the @ syntax inside media queries
@page {
    margin: 0.5cm;
}

/*
--------------------
Content
--------------------
*/

div.clear {
    @extend %clearfix;
}

.section {
    color: $greyish-brown-two;

    &-padding {
        padding: pxToRem(20) 0;
    }

    &-padding-top {
        padding: pxToRem(20) 0 0 0;
    }

    &-padding-bottom {
        padding: 0 0 pxToRem(20) 0;
    }

    &__column {
        &-25 {
            padding: 0 0 pxToRem(20) 0;

            &:last-child {
                padding: pxToRem(20) 0 0 0;
            }
        }
    }

    a {
        color: $greyish-brown-two;

        &:hover,
        &:focus {
            text-decoration: none;
        }

        &.external {

            &::after {
                content: url(../mysource_files/external.svg);
                display: inline-block;
                height: pxToRem(13);
                margin: 0 pxToRem(5);
                width: pxToRem(13);
            }
            &.button::after {
                content: none;
                display: none;
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $greyish-brown-seven;
    }

    &__row {
        @extend %clearfix;
    }

    &__wrapper {
        @extend %clearfix;
        margin: 0 auto;
        max-width: $max-width;
        padding: pxToRem(20) $padding;
        position: relative;
    }

    &-narrow {
        .section {
            &__wrapper {
                max-width: pxToRem(1132); //1052 + 80
            }
        }
    }

    &-wide {
        .section {
            &__wrapper {
                max-width: pxToRem(1950);
            }
        }
    }

    &-merged {
        .section {
            &__wrapper {
                margin-bottom: pxToRem(-20);
                padding-bottom: 0;
            }
        }
    }

    &-orange-top {
        position: relative;

        &:before {
            background-color: $dark-orange;
            content: '';
            height: pxToRem(256);
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
        }

        h1,
        h2 {
            color: $white;
            @include font-smoothing;
        }
    }

    &-orange,
    &-background {
        @include font-smoothing;
        background-color: $dark-orange;
        color: $white;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $white;
        }

        a {
            color: $white;

            &:hover,
            &:focus {
                color: $pastel-orange;
            }
        }
    }

    &-blue {
        @include font-smoothing;
        background-color: #00263E;
        background-image: url(../mysource_files/blue-lookup.png);
        background-position: center center;
        background-repeat: no-repeat;
        color: $white;

        h1 {
            font-size: pxToRem(36);
            line-height: 1;
            padding: pxToRem(20) 0 0;
            font-weight: 400;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $white;
        }

        a {
            color: $white;

            &:hover,
            &:focus {
                color: $pastel-orange;
            }
        }
    }

    &-light-grey {
        background-color: $white-nine;
    }

    &-grey {
        @include font-smoothing;
        background-color: $greyish-brown;
        color: $white;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $white;
        }

        a {
            color: $white;

            &:hover,
            &:focus {
                color: $pastel-orange;
            }
        }
    }

    &-indigo {
        @include font-smoothing;
        background-color: #00263E;
        color: $white;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $white;
        }

        a {
            color: $white;

            &:hover,
            &:focus {
                color: $pastel-orange;
            }
        }
    }

    &-background {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
}

h1 {
    font-size: 2em; //32
    font-weight: 300;
    line-height: 1.31;
    margin: 0;
    padding: pxToRem(40) 0 pxToRem(20);
}

h2 {
    font-size: 1.625em; //26
    font-weight: 300;
    line-height: 1.38;
    margin: 0;
    padding: pxToRem(30) 0 pxToRem(15);

    &.bookmark {
        &:after {
            content: url(../mysource_files/bookmark-orange.svg);
            display: inline-block;
            height: pxToRem(18);
            margin-left: pxToRem(18);
            width: pxToRem(12);
        }

    }
}

h3 {
    font-size: 1.375em; //22
    font-weight: 300;
    line-height: 1.38;
    margin: 0;
    padding: pxToRem(25) 0 pxToRem(10);
}

h4 {
    font-size: 1.250em; //20
    font-weight: 300;
    line-height: 1.4;
    margin: 0;
    padding: pxToRem(20) 0 pxToRem(5);
}

h5 {
    font-size: 1.125em; //18
    font-weight: 500;
    line-height: 1.44;
    margin: 0;
    padding: pxToRem(16) 0 pxToRem(5);
}

h6 {
    font-size: 1em; //16
    font-weight: 500;
    line-height: 1.5;
    margin: 0;
    padding: pxToRem(10) 0 pxToRem(5);
}

.intro {
    color: $brownish-grey;
    font-size: 1.250em; //20
    font-weight: 300;
    line-height: 1.5;
    padding: pxToRem(20) 0;

    p {
        margin: 0;
        padding: pxToRem(12) 0;
    }

    a[href] {
        color: $dark-orange;
        text-decoration: none;
        @include font-smoothing;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.alert {
    background-color: $white-two;
    color: $greyish-brown-four;
    font-size: 1em; //16
    font-weight: 500;
    line-height: 1.63;

    &__wrapper {
        padding: pxToRem(20);
    }

    a {
        color: $dark-orange;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.content {
    color: $brownish-grey;
    font-size: 1em; //16
    font-weight: 300;
    line-height: 1.63;

    &.content-dark {
        color: $greyish-brown-two;
        font-weight: 400;

        ul:not(.note):not(.list-reset) li {
            font-weight: 400;
        }
    }

    figure {
        padding: pxToRem(20) 0;
        width: 100%;

        figcaption {
            border-bottom: 1px solid $white-five;
            color: $greyish-brown-two;
            font-size: 1em; //16
            font-weight: 300;
            line-height: 1.63;
            padding: pxToRem(20) 0 pxToRem(5) 0;
        }

        img {
            margin: 0;
        }
    }

    img {
        margin: pxToRem(20) 0;
    }

    a[href] {
        color: $dark-orange;
        text-decoration: none;
        @include font-smoothing;
        @include break-word;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    p {
        margin: 0;
        padding: pxToRem(8) 0;

        &.tip {
            color: $greyish-brown-four;
            font-size: 0.875em; //14
            line-height: 1.43;
            padding-top: 0;
        }

        &.right,
        &.right-30,
        &.right-50,
        &.left,
        &.left-30,
        &.left-50 {
            font-size: 0.875em; //14

            img {
                margin: 0;
            }
        }
    }

    div {
        &.right,
        &.right-30,
        &.right-50,
        &.left,
        &.left-30,
        &.left-50 {
            font-size: 0.875em; //14
            padding: pxToRem(10) 0;

            img {
                margin: 0;
            }
        }
    }

    blockquote {
        background-color: $white-six;
        color: $brownish-grey;
        font-size: 1rem; //16
        font-style: italic;
        font-weight: 300;
        line-height: 1.63;
        margin: pxToRem(20) 0;
        padding: pxToRem(20) pxToRem(20) pxToRem(30) pxToRem(20);
        @extend %clearfix;
    }

    table {
        border: 1px solid;
        box-sizing: border-box;
        color: $greyish-brown-two;
        min-width: pxToRem(800);
        width: 100%;

        th {
            border: 1px solid $white-seven;
            font-size: 0.875em; //14
            font-weight: 500;
            line-height: 1.6;
            padding: pxToRem(12) pxToRem(10);
            text-align: left;

        }

        td {
            border: 1px solid $white-seven;
            font-size: 0.875em; //14
            font-weight: 300;
            line-height: 1.6;
            padding: pxToRem(12) pxToRem(10);
            vertical-align: top;

            &.orange {
                background-color: rgba($dark-orange, 0.1);
            }

            &.green {
                background-color: rgba($jungle-green, 0.1);
            }

            &.red {
                background-color: rgba($scarlet, 0.1);
            }

            &.yellow {
                background-color: rgba($marigold, 0.1);
            }

            &.grey {
                background-color: $white-nine;
            }

            &.top {
                vertical-align: top!important;
            }

            & > p:first-child {
                padding-top: 0;
            }
        }

        thead {
            background-color: $greyish-brown-six;

            tr {
                border-top: 1px solid $white-seven;

                &:first-child {
                    border-top: none;
                }
            }

            th {
                border: none;
                border-left: 1px solid $white-seven;
                border-right: 1px solid $white-seven;
                color: $white;
                @include font-smoothing;

                &:first-child {
                    border-left: none;
                }

                &:last-child {
                    border-right: none;
                }
            }
        }

        ul:not(.note):not(.list-reset) {
            padding: 0;

            p {
                padding: 0;
            }
        }

        &.clear {
            border: none;
            min-width: 0;
            overflow: hidden;
            width: auto;

            th {
                border: none;
                font-weight: 300;
                padding: 0 pxToRem(4);
                text-align: left;
            }

            td {
                border: none;
                padding: 0 pxToRem(4);
            }

            thead {
                background-color: transparent;
                th {
                    background-color: transparent;
                    border: none;
                    color: $greyish-brown-two;
                    @include font-smoothing-reset;
                    text-align: left;
                }
            }
        }
    }

    code {
        @include break-word;
    }

    .padding {
        padding-bottom: pxToRem(20);
        padding-top: pxToRem(20);

        &-top {
            padding-top: pxToRem(20);
        }

        &-bottom {
            padding-bottom: pxToRem(20);
        }
    }

    &.mobile-padding {
        &-top {
            padding-top: pxToRem(23);
        }
    }

    .table {
        margin: pxToRem(20) 0;
        overflow-x: auto;
    }

    .math {
        .MJX_Assistive_MathML {
            display: none;
        }
    }

    .button,
    .button[href] {
        background-color: $dark-orange;
        border: 1px solid $dark-orange;
        border-radius: 0;
        color: $white;
        display: inline-block;
        font-size: 1em; //16
        font-weight: 500;
        line-height: 1.45;
        margin: pxToRem(24) 0;
        padding: pxToRem(10) pxToRem(20);
        text-decoration: none;
        @include clear-default-appearance;
        @include font-smoothing;
        @include sq-transition(background-color 0.3s, color 0.3s);

        &:hover,
        &:focus {
            background-color: $white;
            color: $dark-orange;
            text-decoration: none;
        }
    }

    .back,
    .back[href] {
        color: $dark-orange;
        font-size: 1.125em; //18
        font-weight: 300;
        line-height: 1.44;
        text-decoration: none;
        @include sq-transition(color 0.3s);

        &:hover,
        &:focus {
            color: $greyish-brown-three;
            text-decoration: none;
        }

        &:before {
            content: url(../mysource_files/arrow-orange.svg);
            display: inline-block;
            height: pxToRem(15);
            line-height: pxToRem(15);
            margin-right: pxToRem(10);
            width: pxToRem(15);
            @include sq-transform(rotate(180deg));
        }
    }

    ol:not(.note):not(.list-reset) {
        counter-reset: item-1;
        margin: 0;
        padding: pxToRem(20) 0;

        li {
            display: block;
            font-size: 1em; //16
            font-weight: 300;
            line-height: 1.63;
            padding: pxToRem(8) 0 pxToRem(8) pxToRem(20);
            position: relative;

            &:last-child {
                padding-bottom: 0;
            }

            ul,
            ol {
                padding: pxToRem(8) 0 0 pxToRem(30);
            }

            &:before {
                box-sizing: border-box;
                color: $dark-orange;
                content: counters(item-1, ".") " ";
                counter-increment: item-1;
                font-weight: 500;
                padding-right: pxToRem(10);
                position: absolute;
                right: 100%;
                transform: translateX(1.25rem);
            }

            ol {
                counter-reset: item-2;
            }

            li {
                &:before {
                    content: counter(item-1)"."counter(item-2,lower-alpha);
                    counter-increment: item-2;
                }

                ol {
                    counter-reset: item-3;
                }

                li {
                    &:before {
                        content: counter(item-1)"."counter(item-2,lower-alpha)"."counter(item-3,lower-roman);
                        counter-increment: item-3;
                    }
                }
            }
        }
    }

    ul:not(.note):not(.list-reset) {
        list-style-type: none;
        margin: 0;
        padding: pxToRem(20) 0;

        li {
            display: block;
            font-size: 1em; //16
            font-weight: 300;
            line-height: 1.63;
            padding: pxToRem(8) 0 pxToRem(8) pxToRem(20);
            position: relative;

            &:last-child {
                padding-bottom: 0;
            }

            ul,
            ol {
                padding: pxToRem(8) 0 0 pxToRem(30);
            }

            ul {
                li {
                    &:after {
                        border-color: $greyish-brown-two;
                    }

                    ul {
                        li {
                            &:after {
                                border: 1px solid $dark-orange;
                                height: pxToRem(2);
                                top: pxToRem(20);
                                width: pxToRem(4);
                            }
                        }
                    }
                }
            }

            &:after {
                border: 1px solid $dark-orange;
                box-sizing: border-box;
                content: '';
                height: pxToRem(5);
                left: 0;
                position: absolute;
                top: pxToRem(17);
                width: pxToRem(5);
            }
        }

        &.plus {
            li {
                &:after {
                    border: none;
                    color: $dark-orange;
                    content: '+';
                    font-weight: 500;
                    height: auto;
                    top: pxToRem(8);
                    width: auto;
                }
            }
        }

        &.minus {
            li {
                &:after {
                    border: none;
                    color: $dark-orange;
                    content: '–';
                    font-weight: 500;
                    height: auto;
                    top: pxToRem(8);
                    width: auto;
                }
            }
        }
    }

    .note {
        color: $gray-medium;
        font-size: 0.875em; //14
        font-weight: 300;
        line-height: 1.4;
        margin: pxToRem(-10) 0 0 0;
        padding: 0 0 pxToRem(10) pxToRem(15);

        li {
            padding-left: pxToRem(15);
        }
    }

    .footnotes {
        border-top: 1px solid $white-five;
        margin-top: pxToRem(20);
        padding: pxToRem(20) 0;

        hr {
            display: none;
        }

        ol,
        ul {
            counter-reset: initial;
            list-style-type: decimal;
            margin: 0;
            padding: 0 0 0 pxToRem(20);

            li {
                display: list-item;
                font-size: 0.875em; //14
                line-height: 1.5;
                padding: 0 0 pxToRem(10) pxToRem(10);

                &:before {
                    display: none;
                }

                &:last-item {
                    padding-bottom: 0;
                }

            }
        }

        p {
            padding: 0;
        }
    }
}


.loader {
    background-color: rgba($white, 0.8);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    z-index: 99;
    @include sq-transition(opacity 0.3s, visibility 0.3s);

    &.active {
        opacity: 1;
        visibility: visible;
    }

    &.hidden {
        display: none;
    }

    &__main {
        animation: spin 2s linear infinite;
        border: pxToRem(8) solid $greyish;
        border-radius: 50%;
        border-top: pxToRem(8) solid $dark-orange;
        height: pxToRem(60);
        left: 50%;
        margin: pxToRem(-30) 0 0 pxToRem(-30);
        position: absolute;
        top: 50%;
        width: pxToRem(60);
    }
}

@keyframes spin {
    0% {
        @include sq-transform(rotate(0deg));
    }
    100% {
        @include sq-transform(rotate(360deg));
    }
}

/*
--------------------
Modules
--------------------
*/