/* Module: S */
.downloads {
	margin: pxToRem(30) 0 pxToRem(40) 0;
	padding: 0 pxToRem(24); 

	&__results {
		border-bottom: 1px solid $white-seven;	
		padding: 0;
	}

	&__item {
		padding: pxToRem(30) 0;
	}

	&__image {
		display: block;
		float: left;
		width: 20%;

		img {
			width: pxToRem(80);
			display: block;
		}
	}

	&__info {
		float: left;
		max-width: pxToRem(420);
		width: 45%;
		padding-right: pxToRem(56);
	}

	&__description {
		padding: 0;
		float: left;
		width: 35%;

		button {
			display: none;
		}
	}

	&__text {
		padding-top: 0;
		display: block;
	}

	&__all {
		padding: pxToRem(30) 0;
	}

	&-simple {
		.downloads {
			&__info {
				width: 50%;
			}

			&__description {
				width: 50%;
			}
		}
	}
}