/* Module: S */
.progress-bar {
    padding: pxToRem(20) 0;

    ol {
        position: relative;
        counter-reset: item-1;
        padding: 0;
        margin: 0;
	    @include sq-flex-box;
	    @include sq-flex-direction(row);
	    @include sq-justify-content(space-between);

	    &:before {
	    	content: '';
	    	position: absolute;
	    	top: pxToRem(14);
	    	left: 0;
	    	right: 0;
	    	height: 0;
            box-sizing: content-box;
			border-width: 4px 0 0 0;
			border-style: dotted;
			border-color: $warm-grey-three;
			border-image-source: url(../mysource_files/border-white.png);
			border-image-slice: 33% 33%;
			border-image-repeat: round;
	    }

        li {
            display: block;
            font-size: 0.875em; //14
            font-weight: 300;
            padding-top: pxToRem(42);
            position: relative;
            margin: 0 auto;

            &:first-child {
                margin-left: 0;

            	&:before {
            		left: 0;
            		width: 50%;
            		padding-right: pxToRem(24);
            		margin: 0;
            	}
            }

            &:last-child {
                margin-right: 0;
                
            	&:before {
            		right: 0;
            		left: auto;
            		width: 50%;
            		padding-left: pxToRem(24);
            		margin: 0;
            	}
            }

            &:before {
            	content: '';
            	background-color: $dark-orange;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
                margin-left: pxToRem(-24);
                width: pxToRem(48);
                box-sizing: content-box;
            }

            &:after {
            	display: block;
                content: counters(item-1, ".") " "; 
                counter-increment: item-1;
                color: $white;
                font-weight: 500;
                height: pxToRem(28);
                width: pxToRem(28);
                line-height: 24px;
                border: 2px solid $white;
                border-radius: 100%;
                text-align: center;
                position: absolute;
                top: 0;
                left: 50%;
                margin-left: pxToRem(-14);
        		background-color: $dark-orange;
            }

            &.active {
            	&:after {
            		background-color: $white;
            		color: $dark-orange;
            	}
            }

            div {
            	position: relative;
            	z-index: 1;
            }

            span {
            	display: none;
            }
        }
    }
}