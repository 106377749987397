/* Module: S */
.side-navigation {
    background-color: $white-nine;
    padding: pxToRem(14) pxToRem(25);
    margin: pxToRem(20) 0;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li {
        padding: 0;
        margin: 0;
        border-bottom: 1px solid $white-five;

        &:last-child {
            border-bottom: none;
        }
    }

    a {
        text-decoration: none;
        font-size: 1em; //16
        font-weight: 300;
        line-height: 1.4;
        display: block;
        padding: pxToRem(10) 0;
        @include sq-transition(color 0.3s);

        &:hover,
        &:focus {
            color: $dark-orange;
        }

        &:after {
            content: url(../mysource_files/arrow-orange.svg);
            width: pxToRem(15);
            height: pxToRem(11);
            margin-left: pxToRem(10);
            display: inline-block;
        }
    }

    &__title {
        display: none;
    }
}