/* Module: S */
body {
	position: relative;

	&.feedback-open {
		overflow: hidden;
	}
}

.feedback {
	display: none;
	
	&.active {
		display: block;
	}	

	&__toggle {
		position: fixed;
		bottom: pxToRem(20);
		right: pxToRem(12);
		text-align: right;
		z-index: 10;
	}

	&__close {
		padding: pxToRem(12);
		margin: 0;
		width: pxToRem(41);
		height: pxToRem(41);
		text-align: center;
		background: transparent;
		border: none;
		-webkit-appearance: none;
		display: inline-block;
		position: absolute;
		right: pxToRem(3);
		top: pxToRem(3);
		outline: none;

		&:hover,
		&:focus {
			svg {
				fill: $dark-orange;
			}
		}

		svg {
			fill: $greyish-brown-two;
		}
	}

	&__hide {
		padding: 0;
		margin: 0;
		width: pxToRem(30);
		height: pxToRem(30);
		text-align: center;
		background: transparent;
		border: none;
		-webkit-appearance: none;
		display: inline-block;
		position: relative;
		right: pxToRem(3);
		bottom: pxToRem(-5);
		outline: none;

		&:after {
			content: url(../mysource_files/close.svg);
			width: pxToRem(20);
			height: pxToRem(20);
			position: absolute;
			top: pxToRem(5);
			left: pxToRem(5);
		}
	}

	&__open {
		padding: 0;
		margin: 0;
		width: pxToRem(66);
		height: pxToRem(66);
		text-align: center;
		background: transparent;
		border: none;
		-webkit-appearance: none;
		display: block;
		outline: none;
		position: relative;

		&:after {
			content: url(../mysource_files/feedback.svg);
			width: pxToRem(66);
			height: pxToRem(66);
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__widget {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $white;;
		padding: pxToRem(70) pxToRem(25) pxToRem(40) pxToRem(25);
		z-index: 10;
		overflow-y: auto;
		display: none;
	}

	&__wrapper {
		max-width: pxToRem(470);
		margin: 0 auto;
	}

	&__title {
		font-size: 1.375em; //22
		font-weight: 300;
		line-height: 1.59;
		color: $greyish-brown-eight;
		padding-bottom: pxToRem(20);
	}

	&__text {
		font-size: 1em; //16
		font-weight: 300;
		line-height: 1.63;
		color: $greyish-brown-four;
	}

	.form {
		padding: 0;

		fieldset {
			legend {
				padding: pxToRem(15) 0;
			}

			.form {
				&__field-item {
					display: inline-block;
					padding-left: pxToRem(20);

					&:first-child {
						padding-left: 0;
					}
				}
			}
		}

		textarea {
			min-height: pxToRem(140);
		}

		&__field {
			&-select {
				padding: pxToRem(24) 0;
			}

			> input[type="submit"] {
				margin-top: pxToRem(10);
			}
		}
	}
}