/* Module: S */
.news-list {
	padding-bottom: pxToRem(20);
	
	+ .pagination {
		border-top: 1px solid $white-five;
	}

	&__item {
		padding: pxToRem(10) 0 pxToRem(20) 0;
	}

	&__image {
		width: 100%;
		padding-bottom: 56.25%; //16x9
		overflow: hidden;
		position: relative;

		&-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-size: cover;
			background-position: center;
		}
	}

	&__date {
		font-size: 0.875em; //14
  		font-weight: 500;
  		line-height: 1.79;
  		text-transform: uppercase;
  		padding: pxToRem(16) 0 pxToRem(2) 0;
		color: $greyish-brown-four;
	}

	&__title {
		font-size: 1.250em; //20
		font-weight: 300;
		line-height: 1.35;

		a {
			color: $greyish-brown-two;
			@include sq-transition(color 0.3s);
			text-decoration: none;

			&:hover,
			&:focus {
				color: $dark-orange;
			}

			&:after {
				content: url(../mysource_files/arrow-orange.svg);
				width: pxToRem(15);
				height: pxToRem(11);
				margin-left: pxToRem(10);
				display: inline-block;
			}
		}
	}

	&__text {
		font-size: 1em; //16
		font-weight: 300;
		line-height: 1.63;
		color: $greyish-brown-two;
		padding-top: pxToRem(24);
		display: none;
	}
}