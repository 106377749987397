/* Module: S */
.link-tiles {
    margin-top: pxToRem(41);
    margin-bottom: pxToRem(29);

	&__item {
		width: 20%;
        padding-top: 0;
		padding-bottom: 0;

        &:nth-child(2n+1) {
            clear: none;
        }

        &:nth-child(5n+1) {
            clear: left;
        }
	}

    &__title {
        padding-top: pxToRem(20);

        &:after {
            display: inline-block;
            margin: 0 0 0 pxToRem(10);
            width: pxToRem(15);
            vertical-align: top;
            line-height: pxToRem(24);
        }
    }
}