/* Module: S */
.header {
	position: relative;

	&__wrapper {
        padding: 0 $padding-desktop;
        max-width: $max-width;
        margin: 0 auto;
	}

    &__accessibility {
    	display: block;
    }

	&__toggle {
		display: none;
	}

	&__navigation {
		float: right;
		padding-top: pxToRem(63);
	}

	&__logo {
		position: relative;
		top: 0;
		left: 0;
		margin-left: 0;
		float: left;
		padding: pxToRem(19) 0;

		img {
			display: block;
			width: pxToRem(111);
		}
	}

	&__cart {
		margin: pxToRem(63) 0 0 0;
		padding: pxToRem(14) 0 pxToRem(22) pxToRem(10);
		height: pxToRem(76);

		a {
            &:hover,
            &:focus {
                svg {
                	g {
                		fill: $dark-orange;
                	}
                }
            }
		}
	}

	&__search {
		top: pxToRem(77);
        padding: 0;
        max-width: $max-width;
        margin: 0 auto;
        background-color: transparent;
    	height: pxToRem(40);
    	z-index: auto;

    	&.active {
    		form {
        		width: pxToRem(588);
    		}
    	}

        form {
        	left: auto;
        	right: pxToRem(70);
        	top: 0;
        	height: pxToRem(40);
        	width: 0;
        	@include sq-transition(width .3s); 
        	z-index: 10;
        	background-color: $white;
        }

		input[type="text"] {
			padding: pxToRem(9) pxToRem(26) pxToRem(9) pxToRem(0);
		}

		input[type="submit"] {
			background-position: right center;
			background-size: pxToRem(20);
			width: pxToRem(30);
			height: pxToRem(40);
		}


		&-toggle {
			margin: pxToRem(63) pxToRem(-10) 0 0;
			padding: pxToRem(14) 0 pxToRem(22) 0;
			position: relative;
			z-index: 10;

            &:hover,
            &:focus {
                svg {
                	fill: $dark-orange;
                }
            }
		}
	}
}