/* Module: S */
.link-tiles {
	@extend %clearfix;
	margin: pxToRem(31) 0 pxToRem(71);

	&__section-title {
		margin: pxToRem(40) 0 pxToRem(14);
		padding: 0;
		font-size: pxToRem(27);
		line-height: pxToRem(32);
		color: $black;
	}

	&__description {
		margin: 0;
		padding: 0;
	}

	&__item {
		padding: pxToRem(15) 0 pxToRem(15);
		width: 100%;
		float: left;
	}

	a {
		text-decoration: none;

		&:hover,
		&:focus {
			.link-tiles {
				&__image {
					&-wrapper {
						@include sq-transform(scale(1.1));
					}
				}

				&__title {
					color: $dark-orange;
				}
			}
		}
	}

	&__image {
		width: 100%;
		padding-bottom: 75%; //4x3
		overflow: hidden;
		position: relative;

		&-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-size: cover;
			background-position: center;
			@include sq-transform(scale(1));
			@include sq-transition(all 0.3s);
		}
	}

	&__title {
		padding-top: pxToRem(23);
		font-size: 1.25em; //20
		line-height: 1.35;
		font-weight: 300;
		color: $greyish-brown-two;
		@include sq-transition(color 0.3s);

		&:after {
			content: url(../mysource_files/arrow-orange.svg);
			display: block;
			margin-top: pxToRem(11);
			width: pxToRem(21);
			height: auto;
		}
	}
}