/* Module: S */
.news-list {
    @include sq-flex-box;
    @include sq-flex-direction(row);
    @include sq-flex-wrap(wrap);
	margin: pxToRem(-15) pxToRem(-6) 0 pxToRem(-6);
	padding-bottom: pxToRem(30);
	
	+ .pagination {
		padding: pxToRem(28) 0;
	}

	&__item {
		width: 33.333%;
		padding: pxToRem(15) pxToRem(6);

		&-large {
			width: 50%;
			padding-bottom: pxToRem(45);

			&:nth-child(1) {
				padding-right: pxToRem(14);
			}

			&:nth-child(2) {
				padding-left: pxToRem(14);
			}

			.news-list {
				&__title {
					font-size: 1.500em; //24
					line-height: 1.38;

					a {
						&:after {
							width: pxToRem(23);
							height: pxToRem(17);
							margin-left: pxToRem(15);
						}
					}

				}
			}
		}
	}

	&__text {
		display: block;
	}
}