/* Module: S */
.share {
	@extend %clearfix;

	a {
		font-size: 1.125em; //18
		font-weight: 300;
		line-height: 1.47;
		display: block;
		padding: pxToRem(5) 0;
		color: $white;
		text-decoration: none;	
		@include font-smoothing;
		position: relative;

		&:hover,
		&:focus {
			color: $white;
			text-decoration: underline;	
		}
	}

	>div {
		padding-top: pxToRem(15);
	}

	&__bookmark {
		a {
			padding-left: pxToRem(27);

			&:after {
				content: url(../mysource_files/bookmark.svg);
				position: absolute;
				top: pxToRem(7);
				left: 0;
				width: pxToRem(13);
				height: pxToRem(20);
			}
		}
	}

	&__addthis {
		display: none;
		
		a {
			padding-left: pxToRem(27);

			&:after {
				content: url(../mysource_files/share.svg);
				position: absolute;
				top: pxToRem(7);
				left: 0;
				width: pxToRem(15);
				height: pxToRem(20);
			}
		}
	}

}

#at_hover.atm-s a:focus, 
#at_hover.atm-s a:focus .at_bold,
.atm-s a:focus {
    background: #2095f0;
    text-decoration: none;
    color: #fff!important;
}

#at15s a.at-branding-logo:focus {
	color: #666;
	text-decoration: underline;
}