/* Module: S */
.page-links {
    width: 100%;
    padding: pxToRem(40) 0 0 0;
    color: $greyish-brown-eight;

    &__title {
        font-size: 1.500em; //24
        font-weight: 300;
        line-height: 1.46;
        padding: pxToRem(12) 0;
        border-bottom: 1px solid $white-five;
    }

    ul {
        list-style-type: none;
        padding: pxToRem(12) 0 0 0;
        margin: 0;
    }

    li {
        padding: pxToRem(12) 0;
        margin: 0;

        a {
            @include sq-flex-box;
            @include sq-flex-direction(row);
            color: $dark-orange;
            @include font-smoothing();
            text-decoration: none;
            font-size: 1em; //16
            font-weight: 300;
            line-height: 1.38;

            &:hover,
            &:focus {
                color: $greyish-brown-eight;
            }

            span {
                display: block;
                padding-left: pxToRem(5);
                word-break: normal;
                word-wrap: normal;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {

                    &:after {
                        content: url(../mysource_files/arrow-orange.svg);
                        width: pxToRem(15);
                        height: pxToRem(11);
                        margin-left: pxToRem(10);
                        display: inline-block;
                    }

                }
            }
        }
    }

    li.has-icon {
        display: flex;
        flex-direction: row;
        align-items: center;

        a {
            display: block;
        }

        span {
            display: inline;
        }
    }

    &__icon {
        margin: 0 pxToRem(21) 0 0;
        border-radius: 50%;
        min-width: pxToRem(46);
        height: pxToRem(46);
        background-color: $white-two;
        background-repeat: no-repeat;
        background-position: center center;

        &.icon-car {
            background-image: url(../mysource_files/icon-car.svg);
        }

        &.icon-guidelines {
            background-image: url(../mysource_files/icon-guidelines.svg);
        }

        &.icon-table {
            background-image: url(../mysource_files/icon-table.svg);
        }
    }

    .section-navigation & {
        margin-bottom: pxToRem(28);
        padding-top: pxToRem(4);

        .page-links__title {
            font-weight: 400;
            font-size: pxToRem(18);
            line-height: pxToRem(21);
        }

        ul {
            border-bottom: 1px solid $white-five;
            padding: pxToRem(5) 0;

            &.icon-indent li:not(.has-icon) {
                padding-left: pxToRem(68);
            }
        }

        li {

            a {
                display: block;

                &:after {
                    content: url(../mysource_files/arrow-orange.svg);
                    width: pxToRem(15);
                    height: pxToRem(11);
                    margin-left: pxToRem(10);
                    display: inline-block;
                }
            }
        }
    }

    .section-navigation.inline-links & a:after {
        content: none;
    }
}