/* Module: S */
.banner-carousel {
	&__item {
		&:before {
			background: -moz-linear-gradient(left, rgba($black-two, 1) 0%, rgba($black-two, 0.95) 40%, rgba($black-two, 0.8) 60%, rgba($black-two, 0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, rgba($black-two, 1) 0%,rgba($black-two, 0.95) 40%,rgba($black-two, 0.8) 60%,rgba($black-two, 0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, rgba($black-two, 1) 0%,rgba($black-two, 0.95) 40%,rgba($black-two, 0.8) 60%,rgba($black-two, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		}
	}

	&__info {
		padding: pxToRem(100) $padding-desktop pxToRem(150) $padding-desktop;
	}

	.slick {
		&-controls {
			bottom: pxToRem(120);
	        padding: 0 pxToRem(35);
	    }
	}
}

.banner-carousel-three-up {
	position: relative;
	margin: pxToRem(-142) auto 0;
	padding: 0 pxToRem(30) pxToRem(75);
	max-width: pxToRem(1320);

	&__wrapper {

		&::before {
			top: pxToRem(142);
		}
	}

	&__content {
		padding: 0 0 pxToRem(7);
	}

	&__image-wrapper {
		margin: 0 0 pxToRem(24);
		height: pxToRem(185);
		min-height: pxToRem(185);
	}

	&__title {
		margin: 0 0 pxToRem(8);
		padding: 0 pxToRem(25);
		font-size: pxToRem(28);
		line-height: pxToRem(38);
		font-weight: 400;
	}

	&__summary {
		margin: 0 0 pxToRem(4);
		padding: 0 pxToRem(25);
	}

	&__icon {
		padding: 0 pxToRem(30);

		&:before {
			width: pxToRem(21);
		}
	}

	.slick {

		&-controls {
			margin-top: pxToRem(46);
		}

		&-dots {

			li {

				&.slick-active {
					button {
						&:after {
							border-color: $dark-orange;
						}
					}
				}
			}

			button {

				&:after {
					border: 1px solid #c4c4c4;
					background-color: transparent;
				}
			}
		}
	}
}