/* Module: S */
.search-page {
	.form {
		padding: pxToRem(20) 0 0 0;
	}

	&__tools {
        @extend %clearfix;

		.form__field {
			clear: left;
			padding-bottom: pxToRem(20);
			width: 100%;

			&-select {
				padding-bottom: pxToRem(30);

				select {
					font-size: 1em;
					font-weight: 500;
					outline: none;

					&:focus {
						border-style: solid;
						border-image: none;
					}
				}
				.publications-subject {
					min-width: 18.75rem;
					padding-right: 2.75rem;
				}
			}

			label {
				font-size: 0.875em; //14
				line-height: 1.86;
				color: $warm-grey;
			}
		}

		input[type="text"] {
			padding: pxToRem(14) pxToRem(16);
			outline: 0;

			&:focus {
				border-color: $greyish;
			}
		}

		input[type="submit"] {
			border: none;
			border-radius: 0;
			background-color: transparent;
			background-image: url(../mysource_files/search.svg);
			background-position: left center;
			background-repeat: no-repeat;
			background-size: pxToRem(20);
			text-indent: -99999px;
			padding: 0;
			margin: 0;
			@include clear-default-appearance;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			padding: 0;
			width: pxToRem(38);
			@include sq-transition(background-color 0.3s);
		}

		.search__partial-list {
			left: 0;
			right: 0;
		}
	}

	&__sort {
		text-align: right;
		padding-top: pxToRem(14);
		margin-bottom: pxToRem(-16);

		label {
			display: inline-block;
			padding: 0 pxToRem(10) 0 0;
		}

		.form__field {
			max-width: 100%;

			&-wrapper {
				display: inline-block;

				&:after {
					height: pxToRem(43);
					line-height: pxToRem(43);
				}
			}

			&-select {
				select {
					display: inline-block;
					border-bottom: none;
					border-image: none;
					padding-right: pxToRem(20);
					font-size: 1em; //16
  					font-weight: 500;
				}
			}
		}
	}
}

@media (min-width: 78.75em) {
	.search-page {
		&__tools {
			.form__field {
				clear: none;
				float: right;
			}
		}
	}
}