/* Module: S */
.block {	
	padding: pxToRem(30) pxToRem(40) pxToRem(40) pxToRem(40);
	margin: pxToRem(30) 0;

    &-50 {
		padding: pxToRem(30) pxToRem(40);
		margin-top: pxToRem(10);
		max-width: 50%;
    }
}