/* Module: S */
.grey-alert-block,
.orange-alert-block {
	display: flex;
	flex-direction: row;
	margin: pxToRem(17) 0;
	padding: pxToRem(28) pxToRem(28) pxToRem(18);

	.content & {
		margin-bottom: pxToRem(27);
	}

	&__icon {
		margin-right: 14px;

		span {
			width: 27px;
			height: 27px;
			display: inline-block;
			border: 1px solid $dark-orange;
			border-radius: 50%;
			text-align: center;
			font-size: pxToRem(18);
			font-weight: 400;
			line-height: pxToRem(25);
			color: $dark-orange;
		}
	}

	&__content {
		padding-top: pxToRem(2);
		color: black;
		font-weight: 300;
		line-height: pxToRem(24);

		p {
			margin-top: 0;
			padding: 0;
		}

		a[href] {
			color: $dark-orange;
			text-decoration: underline;
			-webkit-font-smoothing: auto;

			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}

	.section-navigation + & {
		margin-top: pxToRem(28);
	}
}

.grey-alert-block {
	background-color: #FAFAFA;
}

.orange-alert-block {
	background-color: $dark-orange;

	.content & {

		.orange-alert-block__content {

			ul li::after {
				border-color: white;
			}

			ol li::before {
				color: white;
			}
		}
	}

	&__content {
		color: white;
	}

	&__icon {

		span {
			border-color: white;
			color: white;
		}
	}

	&__content {

		a[href] {
			color: white;
		}
	}
}