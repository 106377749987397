/* Module: S */
.news {
	@extend %clearfix;

	h2 {
		&:after {
			content: url(../mysource_files/arrow-white.svg);
			width: pxToRem(23);
			height: pxToRem(17);
			margin-left: pxToRem(12);
			display: inline-block;
		}

	}

	&__item {
		padding: pxToRem(15) 0 pxToRem(25) 0;
		width: 100%;
		float: left;
	}

	a {
		text-decoration: none;
		position: relative;

		&:hover,
		&:focus {
			.news {
				&__image {
					&-wrapper {
						@include sq-transform(scale(1.1));
					}
				}

				&__title {
					color: $pastel-orange;
				}
			}
		}

		&:after {
			content: url(../mysource_files/arrow-orange.svg);
		    width: pxToRem(29);
		    height: pxToRem(20);
		    float: right;
		    margin-right: 20px;
	    }
	}

	&__image {
		width: 100%;
		padding-bottom: 56.25%; //16x9
		overflow: hidden;
		position: relative;

		&-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-size: cover;
			background-position: center;
			@include sq-transform(scale(1));
			@include sq-transition(all 0.3s);
		}
	}

	&__date {
		font-size: 0.750em; //12
  		font-weight: 500;
  		text-transform: uppercase;
  		padding-top: pxToRem(20);
		@include sq-transition(color 0.3s);
	}

	&__title {
		font-size: 1.125em; //18
		line-height: 1.44;
  		font-weight: 300;
		@include sq-transition(color 0.3s);
		padding: pxToRem(6) 0 pxToRem(14) 0;
	}

	&__text {
		font-size: 1em; //16
		font-weight: 300;
		line-height: 1.63;
		@include sq-transition(color 0.3s);
	}
}

.news-home {
	a {
		&:after {
			display: none;
	  }
	}

	.news {
		&__title {
			font-weight: 500;
		}

		&__date {
			font-size: pxToRem(14);
		}
	}
}

.news-type-2,
.news-landing {
	margin-bottom: pxToRem(71);

	h2 {
		font-size: pxToRem(28);
		line-height: pxToRem(37);
		padding-top: pxToRem(38);
		padding-bottom: pxToRem(33);
	}

	.news {

		&__item {
			float: none;

			a {
				display: block;
				overflow: hidden;
				color: $greyish-brown-two;
			}
		}

		&__info {
			padding: pxToRem(18) pxToRem(18) pxToRem(10);
		}

		&__title {
			font-size: pxToRem(20);
			font-weight: 500;
			padding-bottom: pxToRem(6);
		}

		&__date {
			padding-top: pxToRem(6);
			font-size: pxToRem(14);
		}

		&__text {

			p {
				margin: 0;
				padding: 0;
			}
		}
	}
}

.news-type-2 {
	margin-bottom: 0;

	.tile__title {
		margin-bottom: pxToRem(10);
	}

	.tile__links {
		border-top: 1px solid $white-five;

		a::after {
			float: none;
			margin-right: 0;
		}
	}
}