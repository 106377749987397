/* Module: S */
.banner-homepage {
  height: pxToRem(436);

  &__content {
    padding-top: pxToRem(80);

    p {
      font-size: pxToRem(40);
      line-height: pxToRem(47);
    }

    a {
      margin-top: pxToRem(30);
    }
  }
}