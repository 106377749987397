/* Module: S */
.subscribe {
	padding: pxToRem(10) 0 pxToRem(20) 0;

	h2 {
		padding-top: pxToRem(30);
	}

	.form {
		@include placeholder-color($white);

		&__field {
			max-width: pxToRem(600);
		}
	}
}