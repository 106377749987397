/* Module: S */
.share {
	a {
		font-size: 0.875em; //18
		line-height: 1.57;
		padding: pxToRem(5) 0;
	}

	>div {
		float: left;
		padding-left: pxToRem(32);

		&:first-child {
			padding-left: 0;
		}
	}

	&__bookmark {
		a {
			padding-left: pxToRem(22);

			&:after {
				top: pxToRem(7);
				background-size: pxToRem(11);
			}
		}
	}

	&__addthis {		
		a {
			padding-left: pxToRem(22);

			&:after {
				top: pxToRem(8);
				background-size: pxToRem(13);
			}
		}
	}
}
