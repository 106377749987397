/* Module: S */
.toc {
	padding: pxToRem(30) 0;

	&:not(.toc-plain):not(.toc-left) {
		margin-top: pxToRem(-40);
		padding: 0 0 pxToRem(30) 0;

		.toc {
			&__toggle {
				button {
	        		background-position: pxToRem(20) pxToRem(34);
					padding-left: pxToRem(54);
				}
			}
		}
	}

	+ .content {
		>h1,
		>h2,
		>h3 {
			&:first-child {
				font-size: 2.250em; //36
			    line-height: 1.39;
			    padding: pxToRem(48) 0 pxToRem(15);
			}
		}

		>div {
			&:first-child {
				>h1,
				>h2,
				>h3 {
					&:first-child {
						font-size: 2.250em; //36
					    line-height: 1.39;
					    padding: pxToRem(48) 0 pxToRem(15);
					}
				}
			}
		}
	}

	&__toggle {
		button {
	        background-position: 0 pxToRem(34);
			font-size: 1.500em; //24
			padding: pxToRem(20) 0 pxToRem(20) pxToRem(34);
			width: 100%;

			&:before {
				top: pxToRem(20);
			}

			svg {
				top: pxToRem(36);
			}
		}
	}

	&__item {
		font-size: 1.125em; //18
		padding-bottom: pxToRem(14);
		padding-top: pxToRem(14);

		&.parent {
			padding-right: pxToRem(47);
		}
	}

	&.toc-left {
		padding: 0;

		.toc {

			&__toggle {

				button {
					background-position: pxToRem(20) pxToRem(34);
					padding: pxToRem(15) 0 pxToRem(14) pxToRem(44);
					font-size: pxToRem(20);
					line-height: pxToRem(24);

					&:before {
						top: pxToRem(16);
					}
				}
			}
		}
	}
}