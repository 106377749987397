/* Module: S */
body {
    @include sq-transition((left .3s)); 
    left: 0;
    position: relative;

    .header {
    	@include sq-transition((left .3s)); 
    }

	&.navigation-open {
		left: pxToRem(260);
		overflow: hidden;

		.header {
			left: pxToRem(260);
			-webkit-transform: none;
		}
	}

	&.navigation-fixed {
		.header {
			-webkit-transform: none;
		}
	}
}

.navigation {
    position: fixed;
    top: 0;
    left: pxToRem(-260);
    width: pxToRem(260);
    bottom: 0;
    background-color: $greyish-brown;
    z-index: 99;
    @include sq-transition((left .3s, visibility .3s)); 
    overflow-x: hidden;
    overflow-y: auto;
    visibility: hidden;
    @include font-smoothing;

    &.active {
        left: 0;
        visibility: visible;
    }

    &__wrapper {
        width: 100%;
        padding: pxToRem(24) pxToRem(20);
        min-height: 100%;
        @include sq-transition((left .3s)); 
	}

	&__toggle {
	    position: absolute;
	    top: 0;
	    right: pxToRem(-10);
	    width: pxToRem(40);
	    height: pxToRem(54);
	    padding: pxToRem(20) pxToRem(10);
	    color: $white;
	    background-color: transparent;
	    border: none;
	    border-radius: 0;
	    -webkit-appearance: none;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: $white;
            @include sq-transition((opacity .3s, transform .3s));   
        }

        &:before {
            width: pxToRem(2);
            height: pxToRem(10);
            margin-left: pxToRem(-1);
            margin-top: pxToRem(-5);
        }

        &:after {
            width: pxToRem(10);
            height: pxToRem(2);
            margin-left: pxToRem(-5);
            margin-top: pxToRem(-1);
        }

        &.active {
            &:before {
                @include sq-transform(rotate(90deg));
            }

            &:after {
                @include sq-transform(rotate(90deg));
                opacity: 0;
            }
        }
	}

    &__info {
        display: none;
    }

    &__level-1 {
        @extend %clearfix;
        padding: 0;
        margin: 0;

        >li {
	        padding: 0;
	        margin: 0;
	        list-style-type: none;
            border-bottom: 1px solid $warm-grey;

            &.current {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: pxToRem(-20);
                    bottom: 0;
                    width: pxToRem(4);
                    background-color: $pastel-orange;
                }
            }
        }

        &-link {
        	position: relative;

        	&.parent {
        		a {
        			padding-right: pxToRem(40);
        		}
        	}

        	a {
        		display: block;
        		font-size: 1em; //16
                font-weight: 500;
        		text-decoration: none;
        		color: $white;
        		padding: pxToRem(18) 0 pxToRem(14);
        	}
        }
    }

    &__level-2 {
        @extend %clearfix;
        padding: 0;
        margin: 0;

        &-inner {
            @extend %clearfix;
        }

        &-wrapper {
            display: none;
            padding-bottom: pxToRem(10);

		    &.active {
		        display: block;
		    }

        }

        >li {
	        padding: 0;
	        margin: 0;
	        list-style-type: none;

        	a {
        		display: block;
        		font-size: 0.875em; //14
                line-height: 1.5;
        		text-decoration: none;
        		color: $white;
        		padding: pxToRem(10) 0;
        	}
        }
    }

    &__secondary {
        ul {
            @extend %clearfix;
            padding: pxToRem(26) 0;
            margin: 0;
        }

        li {
            padding: 0;
            margin: 0;
            list-style-type: none;

            a {
                display: block;
                font-size: 0.875em; //14
                text-decoration: none;
                color: $white;
                padding: pxToRem(12) 0;
            }
        }
    }
}