/* Module: S */
.review {
	padding-bottom: pxToRem(20);

	dl {
		margin: 0;
		padding: pxToRem(4) 0;
	}

	dt {
		font-size: 0.875em; //14
		font-weight: 300;
		line-height: 1.64;
		color: $greyish-brown-four;
		padding-top: pxToRem(15);

		&:first-child {
			padding-top: 0;
		}
	}

	dd {
		font-size: 0.875em; //14
		font-weight: 500;
		line-height: 1.64;
		color: $greyish-brown-four;
		margin: 0;
		min-height: pxToRem(22);
	}

	&__column {
		border-top: 1px solid $white-five;
		padding: pxToRem(20) 0;

		&:first-child {
			border-top: none;
			padding-top: 0;
		}
	}

	&__title {
		@extend %clearfix;
		padding: pxToRem(10) 0 pxToRem(15) 0;

		h3 {
			font-size: 1.125em;
			font-weight: 500;
			line-height: 1.94;
			color: $greyish-brown-eight;
			float: left;
			width: 50%;
			padding: 0;
		}

		a {
			display: block;
			float: right;
			text-align: right;
			width: 50%;
			color: $dark-orange;
			text-decoration: none;
			font-size: 0.875em; //14
			font-weight: 300;
			line-height: 1.64;
			@include font-smoothing();
			padding: pxToRem(6) 0;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__item {
		@extend %clearfix;	
		padding: pxToRem(15) 0;
		border-bottom: 1px solid $white-five;

		&:last-child {
			border-bottom: none;
		}	
	}

	&__name {
		font-size: 0.875em; //14
		font-weight: 300;
		line-height: 1.71;
		color: $greyish-brown-two;
		float: left;
		width: 70%;

	}

	&__price {
		font-size: 0.875em; //14
		font-weight: 500;
		line-height: 1.71;
		text-align: right;
		color: $greyish-brown-two;
		float: right;
		width: 30%;

		span {
			display: block;
			font-weight: 300;
			color: $warm-grey;
		}
	}

	&__note {
		font-size: 0.875em; //14
		font-weight: 300;
		line-height: 1.71;
		color: $greyish-brown-two;
		float: left;
		width: 100%;
		padding-right: 30%;
		padding-top: pxToRem(5);
	}

	&__type,
	&__quantity {
		font-size: 0.875em; //14
		font-weight: 300;
		line-height: 1.71;
		color: $warm-grey;
		float: left;
		padding-top: pxToRem(3);
	}

	&__type {
		padding-right: pxToRem(30);
	} 

	&__total {
		background-color: $white-four;
		padding: pxToRem(17) pxToRem(15);
		@extend %clearfix;	

		.review {
			&__name {
				font-weight: 500;
				width: 30%;
			}

			&__price {
				width: 70%;

				span {
					color: $greyish-brown-four;
					padding-top: pxToRem(4);
				}
			}
		}
	}

	&__button {
		padding-top: pxToRem(30);
		text-align: right;
		
		input {
	        border: 1px solid $dark-orange;
	        border-radius: 0;
	        background-color: $dark-orange;
	        color: $white;
	        font-size: 1em; //16
	        line-height: 1.45;
	        font-weight: 500;
	        padding: pxToRem(10) pxToRem(20);
	        @include font-smoothing;
	        @include sq-transition(background-color 0.3s, color 0.3s);
	        display: inline-block;
	        text-decoration: none;

	        &:hover,
	        &:focus {
	            color: $dark-orange;
	            background-color: $white;
	            text-decoration: none;
	        }

		}
	}
}