/* Module: S */
.grey-alert-block,
.orange-alert-block {
  margin: pxToRem(28) 0;

  .content & {
    margin: pxToRem(45) 0 pxToRem(60);
  }

  &__icon {
    margin-right: 20px;

    span {
      width: 40px;
      height: 40px;
      font-size: 24px;
      line-height: 38px;
    }
  }

  &__content {
    padding-top: pxToRem(7);
    font-size: pxToRem(18);
  }

  .section-navigation + & {
    margin-top: pxToRem(45);
  }
}