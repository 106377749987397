/* Module: S */
.toc {
	color: $greyish-brown-eight;
	@include font-smoothing;
	padding: pxToRem(20) 0;

	&__wrapper {

		.search-full-width {
		  margin: pxToRem(10) 0 pxToRem(5);
		}

		.search .js-search-input {
		  padding-top: pxToRem(16);
		  padding-bottom: pxToRem(15);
		  padding-left: pxToRem(15);
		}
	}

	&:not(.toc-plain):not(.toc-left) {
		margin-top: pxToRem(-20);
		padding: 0 0 pxToRem(10) 0;

		.toc {
			&__toggle {
				margin: 0 pxToRem(-20);

				button {
			        background-position: pxToRem(20) pxToRem(27);
					padding-left: pxToRem(48);

					&:before {
						left: pxToRem(20);
					}

					svg {
						right: pxToRem(20);
					}
				}
			}

			&__main {
				&:after {
					left: pxToRem(-20);
					right: pxToRem(-20);
				}
			}
		}
	}

	+ .content {
		>h1,
		>h2,
		>h3 {
			&:first-child {
			    font-size: 1.625em; //26
			    line-height: 1.38;
			    padding: pxToRem(30) 0 pxToRem(15);
			}
		}

		>div {
			&:first-child {
				>h1,
				>h2,
				>h3 {
					&:first-child {
					    font-size: 1.625em; //26
					    line-height: 1.38;
					    padding: pxToRem(30) 0 pxToRem(15);
					}
				}
			}
		}
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	li {
		border-top: 1px solid $white-five;
		margin: 0;
		padding: 0;

		&.current {
			> .toc__item {
				font-weight: 500;
				> a {
					color: $dark-orange;
					text-decoration: underline;
				}
			}
		}
	}

	a {
		color: $greyish-brown-eight;
		text-decoration: none;
		@include sq-transition(color 0.3s);

		&:hover,
		&:focus {
			color: $dark-orange;
		}
	}

	&__toggle {
		position: relative;

		&:after {
			background-color: $white-five;
			bottom: 0;
			content: '';
			height: 1px;
			left: 0;
			position: absolute;
			right: 0;
		}

		button {
		    -webkit-appearance: none;
		    background-color: transparent;
			border: none;
		    border-radius: 0;
            color: $greyish-brown-eight;
			font-size: 1.250em; //20
			font-weight: 300;
			line-height: 1.7;
            outline: none;
			padding: pxToRem(17) 0 pxToRem(17) pxToRem(28);
            position: relative;
            text-align: left;
			width: 100%;
            @include sq-transition(color .3s);

		    &:hover,
		    &:focus {
		    	color: $dark-orange;
		    }

	        &:before {
	            content: url(../mysource_files/menu.svg);
	            display: block;
	            left: 0;
	            position: absolute;
	            top: pxToRem(17);
	            width: pxToRem(18);
	            height: pxToRem(21);
	        }

			svg {
	            height: pxToRem(7);
	            position: absolute;
	            right: 0;
	            top: pxToRem(31);
            	width: pxToRem(13);
	            @include sq-transition(transform .3s);
			}

			&.active {
				svg {
	                @include sq-transform(rotate(180deg));
				}
			}
		}
	}

	&__main {
		display: none;
		position: relative;
        @include sq-transition(visibility .3s, right .3s);

		&:after {
			background-color: $white-five;
			bottom: 0;
			content: '';
			height: 1px;
			left: 0;
			position: absolute;
			right: 0;
		}

		&.active {
			display: block;
		}
	}

	&__close {
		display: none;
		position: absolute;
		right: pxToRem(20);
		top: pxToRem(20);

		button {
			-webkit-appearance: none;
			background: transparent;
			border: none;
			display: inline-block;
			height: pxToRem(40);
			margin: 0;
			outline: none;
			padding: pxToRem(9);
			text-align: center;
			width: pxToRem(40);

			&:hover,
			&:focus {
				svg {
					g {
						stroke: $dark-orange;
					}
				}
			}
		}
	}

	&__title {
	    background-color: transparent;
        color: $greyish-brown-eight;
		display: none;
		font-size: 1.778em; //32
		font-weight: 300;
		line-height: 1.7;
		padding: 0 0 pxToRem(30) pxToRem(34);
        position: relative;

        &:before {
            content: url(../mysource_files/menu.svg);
            display: block;
            left: 0;
            position: absolute;
            top: pxToRem(-2);
            width: pxToRem(18);
            height: pxToRem(48);
        }
	}

	&__item {
		font-size: 1em; //16
		font-weight: 300;
		line-height: 1.38;
		padding: pxToRem(15) 0;
		position: relative;

		&.parent {
			padding-right: pxToRem(47);
		}

		a {
		    @include sq-flex-box;
		    @include sq-flex-direction(row);
		}

		span {
			padding-left: pxToRem(5);

			&:first-child {
				padding-left: 0;
			}
		}

		button {
		    -webkit-appearance: none;
		    background-color: $white-six;
    		border-color: $white;
		    border-radius: 0;
			border-style: solid;
    		border-width: pxToRem(8) pxToRem(6);
			height: pxToRem(40);
			margin-top: pxToRem(-20);
			padding: 0;
			position: absolute;
			right: pxToRem(-6);
			top: 50%;
			width: pxToRem(40);
            @include sq-transition(background-color .3s);

		    &:hover,
		    &:focus {
		    	background-color: $white-eight;
		    }

	        &:before,
	        &:after {
	            background-color: $greyish-brown-two;
	            content: '';
	            position: absolute;
	            right: pxToRem(14);
	            top: pxToRem(12);
	            @include sq-transition(opacity .3s, transform .3s, background-color .3s);
	        }

	        &:before {
				height: pxToRem(10);
	            margin-right: pxToRem(-1);
	            margin-top: pxToRem(-5);
	            width: pxToRem(2);
	        }

	        &:after {
				height: pxToRem(2);
	            margin-right: pxToRem(-5);
	            margin-top: pxToRem(-1);
	            width: pxToRem(10);
	        }

	        &.active {
	            &:before {
	                @include sq-transform(rotate(90deg));
	            }

	            &:after {
	                @include sq-transform(rotate(90deg));
	                opacity: 0;
	            }
	        }
		}
	}

	&__lvl-1 {
		> li {
			&:first-child {
				border-top: none;
			}
		}
	}

	&__lvl-2 {
		display: none;

		&.active {
			display: block;
		}

		> li {
			> .toc__item {
				padding-left: pxToRem(10);

				button {
    				border-width: pxToRem(11) pxToRem(10);
					right: pxToRem(-10);

			        &:before,
			        &:after {
			            right: pxToRem(10);
			            top: pxToRem(9);
			        }

			        &:before {
			            height: pxToRem(8);
			            margin-top: pxToRem(-4);
			        }

			        &:after {
			            margin-right: pxToRem(-4);
			            width: pxToRem(8);
			        }
				}
			}
		}
	}

	&__lvl-3 {
		display: none;

		&.active {
			display: block;
		}

		> li {
			font-size: 0.875em; //14
			font-weight: 300;
			line-height: 1.4;
			padding: pxToRem(16) 0 pxToRem(16) pxToRem(20);

			&.current {
				font-weight: 500;
			}
		}
	}

	&.toc-left {
		padding: 0 0 pxToRem(10) 0;

		.toc {

	      &__toggle {
	        margin: pxToRem(16) 0 0;
	        border: 1px solid $white-seven;
	        padding: 0;

	        &:after {
	          content: none;
	        }

	        button {
	          padding-left: pxToRem(44);
	          font-size: pxToRem(16);
	          font-weight: 400;
	          line-height: pxToRem(19);
	          color: $greyish-brown;
	          background-position: pxToRem(20) pxToRem(27);

	          &:before {
	            top: pxToRem(18);
	            left: pxToRem(11);
	            width: pxToRem(21);
	            height: pxToRem(16);
	          }

	          &:hover,
	          &:focus {
	            color: $dark-orange;
	          }

	          svg {
	            top: pxToRem(24);
	            right: pxToRem(20);
	          }
	        }
	      }

	      &__main {
	        border: 1px solid $white-seven;
	        border-top: none;
	        padding: pxToRem(18) pxToRem(16) pxToRem(22);

	        &:after {
	          content: none;
	        }
	      }

	      &__lvl-2 {

	        & > li {

	          & > .toc__item {
	            padding-bottom: pxToRem(13);
	          }
	        }
	      }

	      &__item {
	        &.section-title {
	          padding-top: pxToRem(34);
	          color: $greyish-brown-two;
	          font-weight: 500;
	        }
	      }

	      // Disable accordions
	      // Remove the rules below to re-enable the TOC accordions
	      &__item {

	        button {
	          display: none;
	        }
	      }
	      // End: Disable accordions
	    }

	    li {
	      border-color: $white-seven;
	    }
	}
}