/* Module: S */
.cart {
	padding-bottom: pxToRem(30);

	&__button {
		text-align: right;

		&-top {
			display: none;
		}

		a {
	        border: 1px solid $dark-orange;
	        border-radius: 0;
	        background-color: $dark-orange;
	        color: $white;
	        font-size: 1em; //16
	        line-height: 1.45;
	        font-weight: 500;
	        padding: pxToRem(10) pxToRem(20);
	        @include font-smoothing;
	        @include sq-transition(background-color 0.3s, color 0.3s);
	        display: inline-block;
	        text-decoration: none;

	        &:hover,
	        &:focus {
	            color: $dark-orange;
	            background-color: $white;
	            text-decoration: none;
	        }

		}
	}

	&__info {
		position: relative;
	}

	&__image {
		display: none;
	}

	&__title {
		font-size: 1.125em; //18
		font-weight: 300;
		line-height: 1.33;
		padding-right: pxToRem(40);
	}

	&__type {
		font-size: 0.875em; //14
		font-weight: 300;
		line-height: 1.71;
		padding-top: pxToRem(8);
		padding-right: 50%;
		color: $warm-grey;
	}

	&__discount {
		font-size: 0.875em; //14
		font-weight: 500;
		line-height: 1.71;
		padding-top: pxToRem(20);
		padding-right: 50%;
		position: absolute;
		bottom: pxToRem(-50);
		left: 0;
	}

	&__remove {
		position: absolute;
		top: pxToRem(-5);
		right: pxToRem(-15);

		a {
			display: block;
			width: pxToRem(40);
			height: pxToRem(40);
			position: relative;
			text-indent: -9999px;

			&:before {
				content: url(../mysource_files/remove.svg);
				width: pxToRem(11);
				display: inline-block;
				position: absolute;
				top: pxToRem(10);
				left: pxToRem(15);
				text-indent: 0;
			}

		}
	}

	&__price {
		font-size: 0.875em; //14
		font-weight: 300;
		line-height: 1.71;
		padding-top: pxToRem(8);
		padding-left: pxToRem(20);
		width: 50%;
		color: $greyish-brown-two;
		text-align: right;
		position: absolute;
		top: pxToRem(-31);
		right: 0;
	}

	&__quantity {
		padding-top: pxToRem(25);
		position: absolute;
		bottom: pxToRem(28);
		left: 0;
		width: 50%;
		z-index: 1;

		input[type="text"],
		input[type="number"] {
	        font-size: 0.875em; //14
	        font-weight: 500;
			color: $black;
			padding: pxToRem(13) pxToRem(6);
			width: 100%;
			border: 1px solid $white-three;
			width: pxToRem(44);
			text-align: center;
		    -webkit-appearance: none;
		}

		input[type='number'] {
			&::-webkit-inner-spin-button, 
			&::-webkit-outer-spin-button { 
			    -webkit-appearance: none;
			    margin: 0;
			}
		}

		input[type="submit"] {
			border: none;
			border-radius: 0;
			background-color: transparent;
			color: $dark-orange;
			font-size: 1em; //16
			line-height: 1.45;
			font-weight: 300;
			padding: pxToRem(10) pxToRem(12);
			@include clear-default-appearance;
			@include font-smoothing;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	&__total {
		margin-left: 50%;
		text-align: right;
		padding-top: pxToRem(27);

		p {
			margin: 0;
			padding: 0;
			font-size: 1.125em; //18
			font-weight: 500;
			line-height: 1.33;		

			&.discount {
				font-weight: 300;
				text-decoration: line-through;
				padding-bottom: pxToRem(20);
			}	
		}

		span {
			display: block;
			font-size: 0.875rem; //14
			font-weight: 300;
			line-height: 1.71;	
			color: $warm-grey;
		}
	}

	&__grand-total {
		@extend %clearfix;
		padding: pxToRem(24) 0 pxToRem(28) 0;

		p {
			width: 50%;
			float: right;
			font-size: 1.500em; //24
			font-weight: 500;
			line-height: 1;	
			margin: 0;
			padding: 0;
			text-align: right;


			&:first-child {
				float: left;
				text-align: left;
			}

			&.note {
				font-size: 0.875rem; //14
				font-weight: 300;
				line-height: 1.71;	
				color: $warm-grey;
			}
		}
	}

	&__notice {
		float: right;
		.note {
			color: $greyish-brown-two;
			font-size: 0.875rem; //14
			font-weight: 300;
			line-height: 1.71;	
		}
	}

	table {
		border-bottom: 1px solid $white-five;
		margin: pxToRem(15) 0;
		width: 100%;

		thead {
			display: none;
		}

		tr {
			border-top: 1px solid $white-five;
			padding: pxToRem(24) 0 pxToRem(28) 0;
			display: block;
			position: relative;
		}

		td {
			display: block;
			padding: 0;

			&:nth-child(2) {
				position: relative;
			}
		}
	}


}