/* Module: S */
.section-tiles {
	@extend %clearfix;

	&__title-two {
		margin: pxToRem(32) 0 0;
		padding: 0 0 pxToRem(32);
		font-size: pxToRem(28);
		line-height: pxToRem(37);
	}

	&__item {
		padding: pxToRem(15) 0 pxToRem(25) 0;
		width: 100%;
		float: left;

		&-short {
			.section-tiles {
				&__image {
					padding-bottom: 37.5%; //16x6
				}
			}
		}
	}

	a {
		text-decoration: none;

		&:hover,
		&:focus {
			.section-tiles {
				&__image {
					&-wrapper {
						@include sq-transform(scale(1.1));	
					}
				}

				&__title {
					color: $dark-orange;
				}
			}
		}
	}

	&__image {
		width: 100%;
		padding-bottom: 56.25%; //16x9
		overflow: hidden;
		position: relative;

		&-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-size: cover;
			background-position: center;
			@include sq-transform(scale(1));
			@include sq-transition(all 0.3s);
		}
	}

	&__title {
		font-size: 1.625em; //26
		line-height: 1.23;
  		font-weight: 300;
		color: $greyish-brown-two;
		@include sq-transition(color 0.3s);	
		padding: pxToRem(22) 0 pxToRem(14) 0;

		a {
			color: $greyish-brown-two;
			@include sq-transition(color 0.3s);

			&:hover,
			&:focus {
				color: $dark-orange;
			}

			&:after {
				content: url(../mysource_files/arrow-orange.svg);
				width: pxToRem(23);
				height: pxToRem(17);
				margin-left: pxToRem(12);
				display: inline-block;
			}
		}
	}

	&__text {
		font-size: 1em; //16
		font-weight: 300;
		line-height: 1.63;
		color: $greyish-brown-two;

		p {
			margin: 0;
			padding: pxToRem(6) 0;
		}
	}

	&__links {
		ul {
			list-style-type: none;
			margin: 0;
			padding: pxToRem(6) 0;
			@extend %clearfix;
		}

		li {
			margin: 0;
			padding: 0;			
		}

		a {
			color: $dark-orange;
			font-size: 1em; //16
			line-height: 1.2;
  			font-weight: 300;
  			padding: pxToRem(8) 0;
  			@include font-smoothing;
  			display: block;
			@include sq-transition(color 0.3s);	

			&:hover,
			&:focus {
				color: $greyish-brown-two;
			}

			&:after {
				content: url(../mysource_files/arrow-orange.svg);
				width: pxToRem(15);
				height: pxToRem(11);
				margin-left: pxToRem(10);
				display: inline-block;
			}

		}
	}

	&__links-large {
		a {
			font-size: 1.125em; //18
			line-height: 1.2;
  			font-weight: 500;
		}
	}
}