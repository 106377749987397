/* Module: S */
.share {
	position: absolute;
	right: $padding-desktop;
	top: pxToRem(40); 

	>div {
		padding-top: 0;
	}

	&__addthis {
		display: block;
	}
}
