/* Module: S */
.search-page {
	&__tools {
		padding-bottom: pxToRem(28);

		.form__field {
			padding-bottom: 0;
			padding-top: pxToRem(32);
			max-width: pxToRem(468);
			width: 65%;

			&:first-child {
				float: left;
			}

			&-select {
				padding-bottom: 0;
				padding-top: 0;
				float: left;
				max-width: pxToRem(360);
				width: 35%;

				.form__field-wrapper {
					display: inline-block;
				}

				select {
					display: inline-block;
				}
			}
		}

		.search__partial-list {
			top: pxToRem(49);
		}
	}

	&__filters {
		position: absolute;
		right: pxToRem(40);
		top: pxToRem(192);
		width: 25%;
		padding-left: pxToRem(28);
	}

	&__sort {
		padding: 0;
		margin: 0;
		position: relative;

		label {
			display: inline-block;
			padding: 0 pxToRem(10) 0 0;
		}

		.form__field {
			max-width: 100%;
			position: absolute;
			right: pxToRem(25);
			bottom: pxToRem(-96);

			&-wrapper {
				display: inline-block;
			}

			&-select {
				label {
					font-size: 0.875em; //14	
					padding-right: pxToRem(4);				
				}

				select {
					font-size: 0.875em; //14
				}
			}
		}
	}
}