/* Module: S */
.subscribe {
	margin: 0 pxToRem(-16);
	padding: pxToRem(13) 0 0 0;

	&__info{
		display: inline-block;
		h4 {
			font-size: 36px;
			font-weight: normal;
			padding: 0;
		}
	}

	h2 {
		float: left;
		padding: 0 pxToRem(16);
		width: 50%;
	}

	.form {
		float: right;
		padding: 0 pxToRem(10);
		width: 50%;

		&__field {
			float: left;
			max-width: pxToRem(250);
			padding: pxToRem(5) pxToRem(6);
			width: 50%;

			&:last-child {
				float: right;
				width: auto;

				&:after {
					margin-top: pxToRem(17);
				}
			}

			>input[type="submit"] {
				margin: 0;
			}
		}
	}
}
