/* Module: S */
.download-links {

  ul {
    margin: 0;

    li {

      a {
        margin-bottom: 0;
      }
    }
  }
}