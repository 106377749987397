/* Module: S */
.filters {
	max-width: pxToRem(420);
	
	&__header {
		font-size: 1.375em; //22
		font-weight: 300;
		line-height: 1.59;
		color: $greyish-brown-eight;
		border-bottom: 1px solid $white-five;
	    padding: pxToRem(10) 0;
	    position: relative;
	    @include font-smoothing;

	    span {
	    	opacity: 0;
	    }

	    button {
	    	position: absolute;
	    	top: 0;
	    	left: 0;
	    	right: 0;
	    	bottom: 0;
		    border: none;
		    border-radius: 0;
		    text-align: left;
		    font-weight: 300;
		    background-color: transparent;
		    -webkit-appearance: none;
		    width: 100%;
		    padding: 0;
            outline: none;

            &:before {
            	content: 'Filter';
	   			@include sq-transition(color .3s);
            }

            svg {
            	width: pxToRem(13);
	   			@include sq-transition(transform .3s);
	            position: absolute;
	            top: 0;
	            right: 0;
	            height: pxToRem(56);
            }

			&:focus {
				&:before {
					color: $dark-orange;
				}
			}

			&.active {
				svg {
	                @include sq-transform(rotate(180deg));
				}
			}

	    }
	}

	&__main {
		display: none;

		&.active {
			display: block;
		}

		ul {
			list-style-type: none;
			padding: pxToRem(5) 0;
			margin: 0;

			&.radio {
				li {
					a {
						&:before {
							content: '';
							position: absolute;
							left: 0;
							top: pxToRem(9);
							width: pxToRem(14);
							height: pxToRem(14);
							border-radius: 100%;
							border: 1px solid $white-eight;
						}
					}

					&.active {
						a {
							&:before {
								border: 1px solid $dark-orange;
							}

							&:after {
								content: '';
								width: pxToRem(6);
								height: pxToRem(6);
								border-radius: 100%;
								position: absolute;
								left: pxToRem(4);
								top: pxToRem(13);
								background-color: $dark-orange;
							}							
						}
					}
				}
			}

			&.checkbox {
				li {
					a {
						&:before {
							content: '';
							position: absolute;
							left: 0;
							top: pxToRem(9);
							width: pxToRem(14);
							height: pxToRem(14);
							border: 1px solid $white-eight;
						}
					}

					&.active {
						a {
							&:before {
								border: 1px solid $dark-orange;
							}

							&:after {
								content: url(../mysource_files/tick.svg);
								position: absolute;
								left: pxToRem(3);
								top: pxToRem(5);
								width: pxToRem(8);
								height: pxToRem(6);
							}						
						}
					}
				}
			}
		}

		li {
			padding: 0;
			margin: 0;

			a {
				position: relative;
				padding: pxToRem(6) 0 pxToRem(6) pxToRem(24);
				display: inline-block;
		        font-size: 0.875em; //14
		        font-weight: 300;
		        color: $greyish-brown-two;
		        text-decoration: none;
		        outline: none;

				&:focus {
					&:before {
						outline: auto 5px -webkit-focus-ring-color;
					}
				}
			}
		}
	}

	&__item {
	    padding: pxToRem(9) 0;
		border-bottom: 1px solid $white-five;
	}

	&__title {
		button {
			font-size: 1em; //16
			font-weight: 500;
			line-height: 1.63;
		    padding: pxToRem(5) pxToRem(24) pxToRem(5) 0;
		    border: none;
		    border-radius: 0;
		    background-color: transparent;
		    -webkit-appearance: none;
		    text-align: left;
		    @include sq-transition(color .3s);   
		    color: $greyish-brown-two;
		    @include font-smoothing;
		    width: 100%;
		    position: relative;
		    outline: 0;

		    &:hover,
		    &:focus {
		    	color: $dark-orange;
		    }

            svg {
            	width: pxToRem(13);
	   			@include sq-transition(transform .3s);
	            position: absolute;
	            top: 0;
	            right: 0;
	            height: pxToRem(36);
            }

			&.active {
				svg {
	                @include sq-transform(rotate(180deg));
				}
			}

		}
	}

	&__body {
		display: none;

		&.active {
			display: block;
		}

		span {
			 font-size: 0.750em; //12
			 line-height: 1.7;
			 display: inline-block;
			 color: $greyish-brown-two;
			 background-color: $white-three;
			 border-radius: pxToRem(24);
			 padding: 0 pxToRem(8);
			 margin-left: pxToRem(3);
		}
	}

	.link {
	    border: none;
	    border-radius: 0;
	    text-align: left;
	    font-weight: 300;
	    background-color: transparent;
	    -webkit-appearance: none;
	    padding: 0;
        outline: none;
        color: $dark-orange;
        @include font-smoothing();
        margin: pxToRem(10) 0 0 0;

        &:hover,
        &:focus {
        	text-decoration: underline;
        }

	}
}