/* Module: S */
.dataTables {
	&_wrapper {
		.dataTables {
			&_filter {
				input {
					min-width: pxToRem(280);
				}
			}
		}
	}
}