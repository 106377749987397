/* Module: S */
.news-landing {
  margin-bottom: pxToRem(47);

  .news__item-wrapper {
    flex-basis: 33%;
    margin-bottom: 0;
  }

  a {
    &::after {
      right: pxToRem(29);
      bottom: pxToRem(16);
      width: pxToRem(21);
    }
  }

  .news__title {
    padding-bottom: pxToRem(13);
    font-size: pxToRem(28);
    line-height: pxToRem(37);
    font-weight: 400;
  }

  .news__info {
    padding-bottom: pxToRem(34);
  }
}

.news-home {

  .news__title {
    padding-bottom: pxToRem(14);
    font-size: pxToRem(20);
    font-weight: 300;
    line-height: pxToRem(27);
  }
}

.news-type-2 {
  display: block;
  margin: 0;
  padding: 0;

  .tile__title,
  .tile__links {
    margin-right: 0;
    margin-left: 0;
  }

  .news__item-wrapper {
    padding-right: 0;
    padding-left: 0;
  }

  .news__info {
    padding-top: pxToRem(15);
  }
}