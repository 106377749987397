/* Module: S */
.events-list {
	padding-bottom: pxToRem(30);
	margin-top: pxToRem(-30);
	
	+ .pagination {
		padding: pxToRem(28) 0;
	}

	&__item {
		padding: pxToRem(30) 0;
		min-height: pxToRem(126);
	}

	&__date {
		top: pxToRem(30);
	}

	&__day {
		font-size: 3.063em; //49
		line-height: 1;
	}

	&__title {
		padding: 0 0 pxToRem(20) pxToRem(95);
	}

	&__info {
		padding: 0 0 pxToRem(20) pxToRem(95);
	}

	&__text {
		display: block;
		padding: 0 0 0 pxToRem(95);
	}
}