/* Module: S */
.breadcrumbs {	
	a { 
		font-size: 0.875em; //18
		line-height: 1.86;

		&:before {
			display: none;
		}
	}

	span {
		font-size: 0.875em; //18
		line-height: 1.86;		
	}

	li {
		display: block;
		position: relative;
		padding-right: pxToRem(40);

		&:after {
			position: absolute;
			top: 0;
			bottom: 0;
			right: pxToRem(12);
			margin-top: pxToRem(3);
			content: url(../mysource_files/arrow-white.svg);
			width: pxToRem(15);
			height: pxToRem(11);
			display: inline-block;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}
}