/* Module: S */
.links-inline {

  &__container {
    margin-top: pxToRem(22);
  }

  &__title {
    padding-bottom: pxToRem(28);
    font-size: pxToRem(36);
    line-height: pxToRem(42);
  }
}