/* Module: S */
.publication {
	padding: pxToRem(10) 0 0 0;

	&__image {
		padding-bottom: pxToRem(16);

		img {
			max-height: pxToRem(152);
		}
	}

	&__title {
		font-size: 1.750em; //28
		font-weight: 300;
		line-height: 1.43;
		padding-bottom: pxToRem(16);
	}

	&__details {
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}

		li {
			color: $greyish-brown-four;
			font-size: 0.875em; //14
			font-weight: 300;
			line-height: 1.64;
			margin: 0;
			padding: pxToRem(4) 0;
		}
	}

	&__links {
		border-top: 1px solid $white-five;
		margin-top: pxToRem(30);
		padding: pxToRem(16) 0 pxToRem(30) 0;

		ul {
			@extend %clearfix;
			list-style-type: none;
			margin: pxToRem(-5) pxToRem(-12);
			padding: 0;
		}

		li {
			color: $greyish-brown-two;
			float: left;
			font-size: 0.875em; //14
			font-weight: 300;
			padding: pxToRem(5) pxToRem(12);
		}

		span {
			display: block;
			padding: pxToRem(13) 0;
		}

		a {
			background-color: $dark-orange;
	        border: 1px solid $dark-orange;
	        color: $white;
			display: inline-block;
			font-size: 0.875em; //14
	        font-weight: 500;
	        line-height: 1.4;
	        padding: pxToRem(8) pxToRem(12);
	        text-decoration: none;
	        @include font-smoothing;
	        @include sq-transition(background-color 0.3s, color 0.3s);

	        &:hover,
	        &:focus {
				background-color: $white;
	            color: $dark-orange;
	            text-decoration: none;
	        }
		}
	}

	&__note {
		background-color: $white-two;
		color: $greyish-brown-four;
		font-size: 1em; //16
		font-weight: 500;
		line-height: 1.63;
		padding: pxToRem(20);

		&.success {
			padding-left: pxToRem(56);
			position: relative;

			&:before {
				content: url(../mysource_files/success.svg);
				display: block;
				height: pxToRem(18);
				left: pxToRem(26);
				position: absolute;
				top: pxToRem(21);
				width: pxToRem(18);
			}
		}
	}
}
