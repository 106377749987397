/* Module: S */
.search {

  &__more-results {
    margin-bottom: pxToRem(41);
  }
}

.search-full-width {
  margin-top: pxToRem(65);

  &__title {
    margin: 0 0 pxToRem(24);
    font-size: pxToRem(36);
    line-height: pxToRem(42);
  }

  input[type="text"] {
    font-size: pxToRem(18);
    line-height: pxToRem(22);
  }

  .search {

    &__partial-list {

      ul {
        padding-top: pxToRem(19);
      }

      a {
        padding-top: pxToRem(12);
        padding-bottom: pxToRem(12);
        font-size: pxToRem(18);
        line-height: pxToRem(22);

        span {
          color: $dark-orange;
        }
      }
    }
  }
}