/* Module: S */
.events {
	h2 {
		margin-bottom: pxToRem(15);

		&:after {
			content: url(../mysource_files/arrow-white.svg);
			display: inline-block;
			margin-left: pxToRem(12);
			width: pxToRem(23);
			height: pxToRem(17);
		}
	}

	&__item {
		align-items: flex-start;
		border-top: 1px solid rgba($white, 0.4);
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: pxToRem(20) 0;
		position: relative;
	}

	a {
		text-decoration: none;

		&:hover,
		&:focus {
			color: $pastel-orange;
		}
	}

	&__date {
		flex-basis: 27.5%;
		position: relative;		

		&:after {
			background-color: rgba($white, 0.4);
			bottom: pxToRem(12);
			content: '';
			position: absolute;
			right: pxToRem(20);
			top: pxToRem(3);
			width: pxToRem(1);
		}
	}

	&__month {
		font-size: 0.875em; //14
  		font-weight: 300;
	}

	&__day {
		font-size: 2.250em; //36
  		font-weight: 300;
	}

	&__title {
		flex-basis: 72.5%;
		font-size: 1.125em; //18
		font-weight: 300;
		line-height: 1.44;
		@include sq-transition(color 0.3s);	

		a {
			display: block;
			
			&:hover,
			&:focus {
				color: $pastel-orange;
			}

			&:after {
				content: url(../mysource_files/arrow-white.svg);
				display: inline-block;
				width: pxToRem(15);
				height: pxToRem(11);
				margin-left: pxToRem(10);
			}
		}
	}

	&__info {
		flex-basis: 72.5%;
		font-size: 0.875em; //14
		font-weight: 300;
		line-height: 1.64;
		margin-left: 27.5%;
		margin-top: 0.875rem;
		@include sq-transition(color 0.3s);	

		p {
			margin: 0;
			padding: 0;
		}

		a {
			color: $pastel-orange;
			font-weight: 500;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}
}