/* Module: S */
.tile {
	border-bottom: 1px solid $white-five;
	margin-bottom: pxToRem(20);
	padding: pxToRem(20) 0;

	&-main {
		border-bottom: none;
		margin-bottom: 0;
	}

	a {
		color: $dark-orange;
		font-weight: 300;
		text-decoration: none;
		@include sq-transition(color 0.3s);
		@include font-smoothing;

		&:hover,
		&:focus {
			color: $greyish-brown-three;
		}
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	li {
		margin: 0;
		padding: pxToRem(16) 0 0 0;

		&:first-child {
			padding: 0;
		}
	}

	p {
		font-size: 1em; //16
		font-weight: 300;
		line-height: 1.63;
		margin: 0;
		padding: 0;
	}

	&__image {
		padding-top: pxToRem(20);

		img {
			width: 100%;
		}
	}

	&__title {
		color: $greyish-brown-seven;
	    font-size: 1.375em; //22
	    font-weight: 300;
	    line-height: 1.38;
	}

	&__text {
		padding-top: pxToRem(20);
	}

	&__info {
		color: $greyish-brown-eight;
		padding-top: pxToRem(20);

		p {
			font-size: 1.125em; //18
			font-weight: 300;
			line-height: 1.44;
		}
	}

	&__links {
		padding-top: pxToRem(20);

		a {
			&:after {
				content: url(../mysource_files/arrow-orange.svg);
				display: inline-block;
				width: pxToRem(15);
				height: pxToRem(11);
				margin-left: pxToRem(10);
			}
		}
	}

	.news:not(.news-type-2) {
		&__item {
			float: none;
			padding: pxToRem(24) 0 0 0;
			width: 100%;

			a {
				color: $greyish-brown-three;

				&:hover,
				&:focus {
					color: $dark-orange;
				}
			}

			+.news__item {
				padding-top: pxToRem(30);
			}
		}

		&__title {
			padding-bottom: 0;

			&:after {
				content: url(../mysource_files/arrow-orange.svg);
				display: inline-block;
				width: pxToRem(15);
				height: pxToRem(11);
				margin-left: pxToRem(10);
			}

		}
	}

	.form {
		padding-bottom: 0;
	}

	.events {
		&__item {
			border: none;
			border-bottom: 1px solid $white-five;
			display: block;
			min-height: 0;
		}

		&__title {
			padding: 0;

			a {
				color: $greyish-brown-eight;
				
				&:hover,
				&:focus {
					color: $dark-orange;
				}

				&:after {
					content: url(../mysource_files/arrow-orange.svg);
					display: inline-block;
					width: pxToRem(15);
					height: pxToRem(11);
					margin-left: pxToRem(10);
				}
			}
		}

		&__info {
			color: $greyish-brown-eight;
			margin: 0;
			padding-left: 0;
			padding-top: pxToRem(10);
			position: static;

			a {
				font-weight: 300;

				&:hover,
				&:focus {
					text-decoration: none;
				}

				&:after {
					content: url(../mysource_files/arrow-orange.svg);
					display: inline-block;
					width: pxToRem(15);
					height: pxToRem(11);
					margin-left: pxToRem(10);
				}
			}
		}
	}
}