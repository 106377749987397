/* Module: S */
.drive-standards {
	margin: pxToRem(40) 0 pxToRem(52);

	&__section-title {
		margin: 0 0 pxToRem(32);
		padding: 0;
		font-size: pxToRem(28);
		line-height: pxToRem(37);
	}

	&__block-one-links {
		border: 1px solid $white-seven;
	}

	&__block-one {

		ul {
			margin: 0;
			padding: pxToRem(39) pxToRem(22) pxToRem(53);
			list-style-type: none;

			li {
				margin: 0;
				padding: 0;
				vertical-align: top;

				a {
					display: block;
					padding: pxToRem(8) 0;
					color: $dark-orange;
					font-size: pxToRem(20);
					line-height: pxToRem(28);
					font-weight: 300;
					text-decoration: none;
					@include sq-transition(color 0.3s 0s);

					&:hover,
					&:focus {
						color: $greyish-brown-two;
					}

					&:after {
						content: url(../mysource_files/arrow-orange.svg);
						display: inline-block;
						margin-left: pxToRem(10);
						width: pxToRem(14);
						height: pxToRem(5);
						vertical-align: text-top;
						line-height: pxToRem(21);
					}
				}
			}
		}

		a.more-info {
			display: block;
			padding: pxToRem(22) pxToRem(22) pxToRem(33);
			font-size: pxToRem(20);
			line-height: pxToRem(28);
			font-weight: 300;
			text-decoration: none;
			@include sq-transition(color 0.3s 0s);

			&:hover,
			&:focus {
				color: $dark-orange;
			}

			&:after {
				content: url(../mysource_files/arrow-orange.svg);
				display: inline-block;
				margin-left: pxToRem(10);
				width: pxToRem(14);
				height: pxToRem(5);
				vertical-align: text-top;
				line-height: pxToRem(21);
			}
		}
	}

	&__block-one-title{
		padding: pxToRem(24) pxToRem(23) pxToRem(23);
		background-color: #00263E;
		font-size: pxToRem(23);
		line-height: pxToRem(31);
		font-weight: 300;
		color: white;
	}

	&__block-two {
		margin-top: pxToRem(20);

		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;

			li {

				& + li a {
					border-top: none;
				}

				a {
					display: block;
					border: 1px solid $white-seven;
					padding: pxToRem(32) pxToRem(24) pxToRem(33);
					font-size: pxToRem(20);
					line-height: pxToRem(33);
					font-weight: 300;
					color: $greyish-brown-two;
					text-decoration: none;
					background-color: white;
					@include sq-transition(background-color 0.3s 0s, color 0.3s 0s);

					&:after {
						content: url(../mysource_files/arrow-dark.svg);
						display: inline-block;
						margin-left: pxToRem(10);
						width: pxToRem(14);
						height: pxToRem(5);
						vertical-align: text-top;
						line-height: pxToRem(21);
					}

					&:hover,
					&:focus {
						color: $dark-orange;
						background-color: #FAFAFA;

						&:after {
							content: url(../mysource_files/arrow-orange.svg);
						}
					}
				}
			}
		}
	}
}