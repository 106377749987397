/* Module: S */
.resources-block {
  margin: pxToRem(57) 0 pxToRem(28);
  padding: pxToRem(36) pxToRem(18) pxToRem(34) pxToRem(48);


  &__content {

    p,
    a {
      font-size: pxToRem(18);
      line-height: pxToRem(26);
    }

    ul {
      padding-left: pxToRem(22);

      // li {

      //   p {
      //     font-size: pxToRem(18);
      //   }
      // }
    }
  }
}