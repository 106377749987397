/* Module: S */
.breadcrumbs {	
	a { 
		font-size: 1.125em; //18
		font-weight: 300;
		line-height: 1.44;
		color: $white;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
			color: $white;
		}

		&:before {
			content: url(../mysource_files/arrow-white.svg);
			width: pxToRem(15);
			height: pxToRem(15);
			line-height: pxToRem(15);
			margin-right: pxToRem(15);
			display: inline-block;
			@include sq-transform(rotate(180deg));
		}
	}

	span {
		font-size: 1.125em; //18
		font-weight: 300;
		line-height: 1.44;
		color: $white;		
	}

	ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
		@extend %clearfix;
	}

	li {
		padding: 0;
		margin: 0;
		float: left;
		display: none;

		&:nth-last-child(2) {
			display: block;
		}
	}
}