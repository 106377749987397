/* Module: S */
.banner-homepage {
	width: 100%;
	height: pxToRem(500);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: $black-two;

	&__content {
		padding: pxToRem(63) pxToRem(20);

		p {
			margin: 0;
			padding: 0;
			max-width: pxToRem(1020);
			color: white;
			font-size: pxToRem(30);
			line-height: pxToRem(37);
			font-weight: 300;
		}

		a {
			display: inline-block;
			border: pxToRem(1) solid white;
			margin: pxToRem(53) 0 0;
			padding: pxToRem(13) pxToRem(19) pxToRem(11);
			font-size: pxToRem(18);
			font-weight: 300;
			line-height: pxToRem(24);
			color: white;
			text-decoration: none;
			background-color: transparent;
			@include sq-transition(background-color 0.3s 0s);

			&:hover,
			&:focus {
				color: $dark-orange;
				background-color: white;

				&:before {
					content: url(../mysource_files/arrow-orange.svg);
				}
			}

			&:before {
				content: url(../mysource_files/arrow-white.svg);
				display: inline-block;
				margin-right: pxToRem(20);
				width: pxToRem(21);
				height: auto;
				vertical-align: top;
				line-height: pxToRem(25);
			}
		}
	}
}