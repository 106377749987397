/* Module: S */
.dynamic-table {
	width: 100%;

	span {
		display: inline-block;
		@include font-smoothing;

		&.on-track {
			color: $jungle-green;
		}

		&.delays {
			color: $dark-orange;
		}

		&.late {
			color: $scarlet;
		}
	}
}

.content {
	.dynamic-table,
	.custom-table {
		min-width: 0;

		button {
	        margin: 0;
	        border: 1px solid $dark-orange;
	        border-radius: 0;
	        background-color: $dark-orange;
	        color: $white;
	        font-size: 1em; //16
	        line-height: 1.45;
	        font-weight: 500;
	        padding: pxToRem(10) pxToRem(20);
	        @include clear-default-appearance;
	        @include font-smoothing;
	        @include sq-transition(background-color 0.3s, color 0.3s);
	        display: inline-block;
	        text-decoration: none;

	        &:hover,
	        &:focus {
	            color: $dark-orange;
	            background-color: $white;
	            text-decoration: none;
	        }
		}
	}

	.dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>td {
		vertical-align: top;
		}
}

.dataTables {
	&_wrapper {
		padding: pxToRem(20) 0;
		overflow: hidden;

		&.no-footer {
			.dataTables_scrollBody {
				border-bottom: none;
			}
		}

		.dataTables {
			&_filter {
				padding: pxToRem(10) 0;
				text-align: left;

				label {
					font-size: 0.875em; //14
					font-weight: 300;
					line-height: 1.64;
					color: $greyish-brown-four;

				}

				input {
			        font-size: 0.875em; //14
			        font-weight: 300;
					color: $black;
					padding: pxToRem(13) pxToRem(16) pxToRem(13) pxToRem(16);
					min-width: pxToRem(180);
					border: 1px solid $white-three;
					margin-left: pxToRem(10);
				}
			}

			&_info {
				font-size: 0.875em; //14
				font-weight: 300;
				line-height: 1.64;
				color: $greyish-brown-four;
				padding: pxToRem(10) 0;		
				text-align: left;		
			}

			&_paginate {
				padding: pxToRem(4) 0;

				.paginate_button {
					text-decoration: none;
					color: $dark-orange !important;
					font-size: 1.125em; //18
					font-weight: 300;
					line-height: 1.56;
					padding: pxToRem(5) pxToRem(10);
					@include sq-transition(color 0.3s);
					@include font-smoothing;
					vertical-align: top;
					position: relative;
					min-width: 0;
					border: none;
					border-radius: 0;
					margin: 0;

					&:after {
						content: '';
						position: absolute;
						bottom: 0;
						left: pxToRem(10);
						right: pxToRem(10);
						height: 1px;
						background-color: $dark-orange;
					}

					&:hover,
					&:focus {
						color: $greyish-brown-four !important;
						border: none;
						background: transparent;

						svg {
							path {
								fill: $greyish-brown-four !important;
							}
						}
					}

					&.current {
						color: $greyish-brown-four !important;
						border: none;
						background: transparent;

						&:hover,
						&:focus {
							color: $greyish-brown-four !important;
							border: none;
							background: transparent;
						}

						&:after {
							display: none;
						}
					}

					&.disabled {
						border: none;

						&:hover,
						&:focus {
							border: none;
						}
					}

					&.previous,
					&.next {
						text-indent: -9999px;
						width: pxToRem(35);
						height: pxToRem(37);
						position: relative;

						&:after {
							display: none;
						}

						&:before {
							position: absolute;
							top: 0;
							bottom: 0;
							line-height: pxToRem(36);
							left: 50%;
							margin: 0 0 0 pxToRem(-7);
							content: url(../mysource_files/arrow-orange.svg);
							width: pxToRem(15);
							height: pxToRem(36);
							display: inline-block;
							text-indent: 0;
						}

						&:hover,
						&:focus {
							&:before {
								content: url(../mysource_files/arrow-dark.svg);
							}
						}

						&.disabled {
							&:before {
								content: url(../mysource_files/arrow-dark.svg);
								opacity: 0.5;
							}							
						}
					}

					&.previous {
						@include sq-transform(rotate(180deg));
					}

				}
			}
		}
	}

	&_scrollBody {
		table {
			thead {
				border: none;

				tr {
					border: none;
				}

				th {
					border: none;
				}

				td {
					vertical-align: top;
				}
			}

		}
	}
}

table {
	&.dataTable {
		margin: pxToRem(20) 0;
	    width: 100%;
    	float: left;
    	border-collapse: collapse;

		&.no-footer {
			border-bottom: 1px solid transparent;
		}

		&.mega-table,
		&.custom-table {
			margin: 0;
			border-bottom: none;
		}

		&.dtr-inline.collapsed {
			>tbody>tr[role="row"] {
				>td,
				>th {
					&:first-child {
						&:before {
							top: pxToRem(16);
							left: pxToRem(5);
							height: pxToRem(16);
							width: pxToRem(20);
           					color: $greyish-brown-two;
							border: none;
							border-radius: 0;
							box-shadow: none;
							font-size: pxToRem(18);
							line-height: pxToRem(16);
		    				background-color: $white-six;
		    				font-weight: 500;
						}
					}
				}

			}
		}

		ul {
			li {
				&:before {
					display: none;
				}
			}
		}

		thead,
		tbody {
			th,
			td {
            	padding: pxToRem(12) pxToRem(10);
			}
		}

		thead,
		tfoot {
			th {
				font-weight: 500;
			}
		}

		tbody {
			>tr.child {
				ul.dtr-details {
				 	> li {
						border-bottom: none;
						&::after {
							content: none;
							display: none;
						}
				 	}
				}

				span.dtr-title {
					font-weight: 500;
					min-width: pxToRem(100);

					&:after {
						content: ':';
					}
				}
			}
		}

		thead {		
			.sorting,
			.sorting_asc,
			.sorting_desc {
				background-image: none;
				padding-right: pxToRem(33);
				position: relative;
				overflow: hidden;
				min-height: pxToRem(69);
				height: pxToRem(69);
				padding-top: 0;
				padding-bottom: 0;

				&:after {
					content: '';
					background-image: url(../mysource_files/arrow-down-pastel-orange.svg);
					background-position: center;
					background-repeat: no-repeat;
					position: absolute;
					right: pxToRem(10);
					background-size: pxToRem(11);
					width: pxToRem(11);
					min-height: pxToRem(69);
					top: 0;
					bottom: 0;
					display: block;			
				}
			}

			.sorting_asc {
				&:after {
					@include sq-transform(rotate(180deg));		
				}
			}

			.sorting {
				&:after {
					background-image: url(../mysource_files/arrow-double.svg);
				}
			}
		}
	}
}