/* Module: S */
.tile-homepage {
	margin: pxToRem(45) 0 pxToRem(39);

	&__title {

		a {
			font-size: pxToRem(36);
			line-height: pxToRem(42);
		}
	}

	&__text {
		margin-top: pxToRem(13);
		// padding-bottom: pxToRem(38);
		padding: 0 0 pxToRem(38);
		border-bottom: pxToRem(1) solid $white-seven;
	}

	&__item {
		display: flex;
		justify-content: space-between;
	}

	&__image {
		flex-basis: 45%;
		margin: 0 pxToRem(61) 0 0;

		img {
			max-width: none;
		}
	}

	&__info {
		flex-basis: 58.5%;
	}

	&__links {

		ul {
			display: flex;
			flex-wrap: wrap;
			margin-top: pxToRem(11);

			li {
				flex-basis: 33.33%;

				&:nth-child(3n) a {
					padding-right: 0;
				}

				a {
					padding: pxToRem(15) pxToRem(18) pxToRem(15) 0;
					font-size: pxToRem(16);
					line-height: pxToRem(19);
				}
			}
		}
	}
}