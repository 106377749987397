/* Module: S */
.tile {
	padding: pxToRem(30) 0;
	margin-bottom: pxToRem(30);

	&-main {
		margin-bottom: 0;
	}

	&__image {
		padding-top: pxToRem(30);
	}

	&__title {
	    font-size: 1.750em; //28
	    line-height: 1.43;
	}

	&__text {
		padding-top: pxToRem(30);
	}

	&__info {
		padding-top: pxToRem(30);
	}

	&__links {
		padding-top: pxToRem(24);
	}

	.news:not(.news-type-2) {
		&__item {
			+.news__item {
				padding-top: pxToRem(40);
			}
		}

		&__title {
			font-size: 1.125em; //18
			line-height: 1.44;
		}

		&-3 {
	        @include sq-flex-box;
	        @include sq-flex-direction(row);
			margin: 0 pxToRem(-12);

			.news__item {
				flex-basis: 33.33%;
				padding: pxToRem(30) pxToRem(12) 0 pxToRem(12);
			}
		}
	}
}