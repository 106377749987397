/* Module: S */
.section-tiles {
	margin: pxToRem(-20) pxToRem(-10);

	&__title-two {
		padding: 0 0 pxToRem(65);
	}

	&__title {
		padding-top: pxToRem(27);
		padding-bottom: pxToRem(7);
	}

	&__item {
		padding: pxToRem(20) pxToRem(10) pxToRem(43);
		width: 50%;

        &:nth-child(2n+1) {
            clear: left;
        }
	}
}