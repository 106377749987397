/* Module: S */
.progress-bar {
	right: pxToRem(40);
	bottom: pxToRem(60);
	min-width: 50%;

    ol {
        li {
            padding-top: 0;
            padding-left: pxToRem(53);
            padding-right: pxToRem(15);
            background-color: $dark-orange;
            line-height: pxToRem(28);

            &:after {
                top: 0;
                left: pxToRem(15);
                margin-left: 0;
            }

            &:before {
            	display: none;
            }

            &:first-child {
            	&:before {
            		left: 0;
            		width: 50%;
            		padding-right: pxToRem(24);
            		margin: 0;
            	}

            }

            &:last-child {
            	&:before {
            		right: 0;
            		left: auto;
            		width: 50%;
            		padding-left: pxToRem(24);
            		margin: 0;
            	}
            }

            span {
            	display: inline;
            }
        }
    }
}