/* Module: S */
.footer {
	color: $white;
    background-color: $black-two;
    @include font-smoothing;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    li {
        margin: 0;
        padding: 0;
    }

    a {
        color: $white;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__wrapper {
        padding: pxToRem(16) $padding;
        max-width: $max-width;
        margin: 0 auto;   
        @extend %clearfix;   
    }

    &__primary {
        @extend %clearfix;  

        ul {
            padding: pxToRem(16) 0;            
        }

        li {
            padding: pxToRem(16) 0;
        }

        a {
            font-size: 1.125em; //18
            font-weight: 300;
            line-height: 1.22;
        } 
    }

    &__secondary {
        @extend %clearfix; 

        ul {
            padding: pxToRem(16) 0;            
        }

        li {
            padding: pxToRem(14) 0;
        }

        a {
            font-size: 1em; //16
            font-weight: 300;
            line-height: 1.38;
        }   
    }

    &__label {
        font-size: 1.125em; //18
        font-weight: 300;
        float: left;
        padding: pxToRem(10) pxToRem(10) pxToRem(10) 0;
    }

    &__social {
        margin-top: pxToRem(-10);
        padding-bottom: pxToRem(20);
        @extend %clearfix; 

        ul {
            float: left;
        }

        li {
            float: left;
            padding: 0 pxToRem(5);
        }

        a {
            display: block;
            width: pxToRem(40);
            height: pxToRem(40);
            padding: pxToRem(10);
        }

        svg {
            fill: $white;
            width: pxToRem(20);
            height: pxToRem(20);
        }
    }

    &__copyright {
        font-size: 1em; //16
        font-weight: 300;
        line-height: 1.38;
        padding: 0 0 pxToRem(35) 0;
    }   
}