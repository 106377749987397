/* Module: S */
.tabs {
	border: none;

	&__controls {
		display: block;
	}

	&__panels {
		border: 1px solid $white-seven;

		.tabs {
			&__title {
				display: none;
			}
		}
	}

	&__item {
		border-top: none;
	}

	&__title {
		float: left;

		button {
		    width: 100%;
		    padding: pxToRem(14) pxToRem(26);
		    font-size: 1.125em; //18
  			line-height: 1.44;
  			border: 1px solid transparent;
	        
	        &:before,
	        &:after {
	            display: none; 
	        }
		}

        &.active {
        	&:after {
        		content: '';
        		position: absolute;
        		bottom: -1px;
        		left: 1px;
        		right: 1px;
        		height: 1px;
        		background-color: $white;
        	}

            button {
		    	border: 1px solid $white-seven;
		    	border-bottom: 1px solid transparent;
            }
        }
	}

	&__text {
		border-top: none;
		font-size: 1em; //16
		font-weight: 300;
		line-height: 1.63;
		padding: pxToRem(36) pxToRem(26);
	}
}