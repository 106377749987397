//Colors
$white: #ffffff;
$white-two: #ebebeb;
$white-three: #d9d9d9;
$white-four: #f2f2f2;
$white-five: #e7e7e7;
$white-six: #f3f3f3;
$white-seven: #e3e3e3;
$white-eight: #d2d2d2;
$white-nine: #f8f8f8;

$black: #000000;
$black-two: #292929;

$brownish-grey: #61605f;

$gray-medium: #727272;
$warm-grey: #868686;
$warm-grey-two: #737373;
$warm-grey-three: #979797;
$greyish: #acacac;
$greyish-brown: #424242;
$greyish-brown-two: #3c3533;
$greyish-brown-three: #443d3b;
$greyish-brown-four: #555555;
$greyish-brown-five: #4f4f4f;
$greyish-brown-six: #3c3c3c;
$greyish-brown-seven: #48413f;
$greyish-brown-eight: #413a38;


$pastel-orange: #ff9252;
$dark-orange: #cc4c00;
$rust-red: #bb0707;


$purple: #7659b6;
$jungle-green: #007233;
$scarlet: #d0021b;
$marigold: #ffff07;

// Base font
$base-font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: $greyish-brown-two;

// Selection highlighting
$content-selection-highlight: #b3d4fc;


// Max design width
$max-width: 82.5rem; //1320 = 1280 + 40
$padding: 1.25rem; //20
$padding-desktop: 2.5rem; //40