/* Module: S */
.page-links {
    padding: pxToRem(60) 0 0 0;

    ul {
        padding: pxToRem(20) 0 0 0;
    }

    li {
        padding: pxToRem(8) 0;
    }

    .section-navigation & {
        margin-bottom: pxToRem(31);
        padding-top: pxToRem(22);

        .page-links__title {
            font-size: 1.500em;
            line-height: 1.46;
            padding-bottom: pxToRem(20);
        }

        ul {
            padding-top: pxToRem(22);
            padding-bottom: pxToRem(25);
        }

        li {
            padding: pxToRem(14) 0;

            &.has-icon {
                padding: pxToRem(8) 0;
            }

            a {
                font-size: pxToRem(20);
                line-height: pxToRem(24);
            }
        }
    }
}