/* Module: S */
.footer {
    &__wrapper {
        padding: pxToRem(36) $padding-desktop;
    }

    &__primary {
        float: left;

        ul {
            padding: 0;  
            margin: 0 pxToRem(-15);   
            float: left;        
        }

        li {
            padding: pxToRem(16) pxToRem(15);
            float: left;     
        }

        a {
            font-size: 0.875em; //14
            line-height: 1.57;
        } 
    }

    &__secondary {
        float: left;
        clear: left;

        ul {
            padding: 0; 
            margin: 0 pxToRem(-10);  
            float: left;         
        }

        li {
            padding: pxToRem(16) pxToRem(10);
            float: left;
        }

        a {
            font-size: 0.875em; //14
            line-height: 1.57;
        }   
    }

    &__label {
        font-size: 0.875em; //14
        line-height: 1.57;
        padding: pxToRem(8) pxToRem(10) pxToRem(11) 0;
    }

    &__social {
        float: right;
        margin-top: 0;
        margin-right: pxToRem(-15);
        padding: pxToRem(7) 0;

        a {
            &:hover,
            &:focus {
                svg {
                    fill: rgba($white, 0.6);
                }                
            }
        }
    }

    &__copyright {
        float: right;
        font-size: 0.875em; //14
        line-height: 1.57;
        padding: pxToRem(16) 0;
    }   
}